import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/* Components */
import { PaginationButton } from '../../components/buttons';
import {
  DonationFormContent,
  DonationFormFooter,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { PaginationButtonGroup } from '../../components/groups';
import { DonationSteps } from '../../components/stepper';

/* Constants */
import {
  DONOR_INFORMATION,
  SELECT_CURRENCY,
  DONATION_AMOUNT,
  REVIEW_DONATION,
  PAYMENT_METHOD,
  DONATION_CONFIRMATION,
  CUSTOMIZATIONS,
} from '../../constants/formSections';

/* Dispatch */
import {
  setFormSection,
  toggleIsDonationUnderReview,
} from '../../actions/donationActions';

/* Libraries */
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import firebase from '../../../../firebase';
import axios from 'axios';
import moment from 'moment';

/* Models */
import { acceptedDonationCurrencies } from '../../../../models/currencies';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';

/* Styles */
import './ReviewDonation.scss';

export default function ReviewDonation() {
  const {
    donationAmount,
    donationAmountUSD,
    donationCurrency,
    donorDisplayName,
    donorEmailAddress,
    donorFullLegalName,
    donorWalletAddress,
    isDonorAnonymous,
    transactionId,
    coinbaseToken,
    willDonorReceiveTaxReceipt,
    willDonorReceiveUpdates,
    onlineWalletPlatform,
  } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();

  const [tableRows, setTableRows] = useState([
    {
      label: 'Currency',
      data: donationCurrency,
      source: SELECT_CURRENCY,
    },
    {
      label: 'Amount',
      data: donationAmount,
      source: DONATION_AMOUNT,
    },
    {
      label: 'Display Name',
      data: donorDisplayName,
      source: DONOR_INFORMATION,
    },
    {
      label: 'Email',
      data: donorEmailAddress,
      source: DONOR_INFORMATION,
    },
    {
      label: 'Project Updates',
      data: willDonorReceiveUpdates ? (
        <DoneOutlinedIcon color='action' />
      ) : (
        'N/A'
      ),
      source: CUSTOMIZATIONS,
    },
    {
      label: 'Tax Receipt',
      data: willDonorReceiveTaxReceipt ? (
        <DoneOutlinedIcon color='action' />
      ) : (
        'N/A'
      ),
      source: CUSTOMIZATIONS,
    },
    {
      label: 'Recurring Donation',
      data: willDonorReceiveUpdates ? (
        <DoneOutlinedIcon color='action' />
      ) : (
        'N/A'
      ),
      source: CUSTOMIZATIONS,
    },
  ]);

  useEffect(() => {
    if (!!willDonorReceiveTaxReceipt) {
      setTableRows((rows) => [
        ...rows,
        {
          label: 'Full Legal Name',
          data: donorFullLegalName,
          source: DONOR_INFORMATION,
        },
      ]);
    }
  }, [donorFullLegalName, willDonorReceiveTaxReceipt]);

  const completeDonation = () => {
    submitDonation();

    if (onlineWalletPlatform === 'coinbase') {
      getCoinbaseUserAccount();
    }

    if (willDonorReceiveTaxReceipt) {
      completeThankYouEmail(donorDisplayName, donorEmailAddress);
    }
  };

  const completeThankYouEmail = async (name, email) => {
    axios
      .post(
        'https://81rfbqicud.execute-api.us-east-2.amazonaws.com/dev',
        {
          name: name,
          email: email,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((response) => {})
      .catch((e) => {
        console.error(e);
      });
  };

  const composeDatabaseRecord = () => {
    return {
      donationAmount,
      donationAmountUSD,
      donationCurrency,
      donorDisplayName,
      donorEmailAddress,
      donorFullLegalName,
      donorWalletAddress,
      isDonorAnonymous,
      transactionId,
      willDonorReceiveTaxReceipt,
      willDonorReceiveUpdates,
      onlineWalletPlatform,
    };
  };

  const createRipple = (event) => {
    // Access the container to host the ripple effect
    const container = event.currentTarget;

    // Instantiate the ripple and calculate dimensions
    const circle = document.createElement('span');
    const diameter = Math.max(container.clientWidth, container.clientHeight);
    const radius = diameter / 2;

    // Define remaining properties
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - (container.offsetLeft + radius)}px`;
    circle.style.top = `${event.clientY - (container.offsetTop + radius)}px`;
    circle.classList.add('ripple');

    // Remove any existing ripples
    const leftoverRipple = container.getElementsByClassName('ripple')[0];
    if (leftoverRipple) {
      leftoverRipple.remove();
    }

    // Append to DOM
    container.appendChild(circle);
  };

  const getCoinbaseUserAccount = async () => {
    const ticker = getCurrencyObject().tickerSymbol;

    axios
      .get(`https://api.coinbase.com/v2/accounts/${ticker}`, {
        headers: {
          Authorization: 'Bearer ' + coinbaseToken,
        },
      })
      .then((response) => {
        sendMoneyViaCoinbase(response.data.data);
      })
      .catch((err) => console.error(err));
  };

  const getCurrencyObject = () => {
    return acceptedDonationCurrencies.find(
      (obj) => obj.network === donationCurrency
    );
  };

  const onRowClick = (event, formSection) => {
    if (window.innerWidth <= 400) {
      createRipple(event);
      setTimeout(() => {
        reviewFormSection(formSection);
      }, 200);
    }
  };

  const reviewFormSection = (formSection) => {
    dispatch(toggleIsDonationUnderReview());
    dispatch(setFormSection(formSection));
  };

  const sendMoneyViaCoinbase = async (account) => {
    const currentObject = getCurrencyObject();

    axios
      .post(
        `https://api.coinbase.com/v2/accounts/${account.id}/transactions`,
        {
          amount: donationAmount,
          to: currentObject.walletAddress,
          currency: currentObject.tickerSymbol,
          type: 'send',
        },
        {
          headers: {
            Authorization: 'Bearer ' + coinbaseToken,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  const submitDonation = () => {
    const projectId = 1;
    const donationRecord = composeDatabaseRecord();
    const date = moment(Date.now()).format('MM/DD/YYYY');
    const time = new Date().toLocaleTimeString();

    firebase
      .database()
      .ref(`projects/${projectId}/donations`)
      .push({
        ...donationRecord,
        donationDate: date,
        donationTime: time,
      });

    firebase
      .database()
      .ref(`projects/${projectId}/totalDonationAmount`)
      .transaction((currentTotalDonationAmount) => {
        return parseFloat(
          Number(currentTotalDonationAmount) + Number(donationAmountUSD)
        );
      })
      .then(dispatch(setFormSection(DONATION_CONFIRMATION)));
  };

  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader onCloseCallback={toggleIsFormOpen}>
            <h1 className='display-4 text-center'>
              {!!transactionId ? 'Donation Receipt' : 'Review Donation'}
            </h1>
            <DonationSteps activeStep={REVIEW_DONATION} />
          </DonationFormHeader>

          <DonationFormContent>
            <div className='reviewDonationTable__wrapper'>
              <table>
                <tbody>
                  {tableRows.map((row) => (
                    <tr
                      key={row.label}
                      className='row'
                      onClick={(event) => onRowClick(event, row.source)}>
                      <td className='col-12 col-mb-4'>
                        <span>{row.label}</span>
                      </td>
                      {transactionId.length === 0 ? (
                        <>
                          <td className='col-12 col-mb-7'>
                            <span>{row.data}</span>
                          </td>
                          <td className='col-mb-1 editButton'>
                            <EditOutlinedIcon
                              color='action'
                              onClick={(event) => reviewFormSection(row.source)}
                            />
                          </td>
                        </>
                      ) : (
                        <td className='col-12 col-mb-8'>
                          <span>{row.data}</span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </DonationFormContent>

          <DonationFormFooter>
            <PaginationButtonGroup>
              <PaginationButton
                onClickHandler={completeDonation}
                type='primary'>
                Complete Donation
              </PaginationButton>
              {transactionId.length === 0 && (
                <PaginationButton
                  onClickHandler={() =>
                    dispatch(setFormSection(PAYMENT_METHOD))
                  }
                  type='secondary'>
                  Previous
                </PaginationButton>
              )}
            </PaginationButtonGroup>
          </DonationFormFooter>
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
