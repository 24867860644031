import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaymentMethodCard } from '../../../components/cards';

/* Constants */
import * as section from '../../../constants/formSections';

/* Dispatch */
import { setFormSection } from '../../../actions/donationActions';

/* Libraries */
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import ExtensionIcon from '@material-ui/icons/Extension';
import LanguageIcon from '@material-ui/icons/Language';
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';

/* Models */
import { acceptedDonationPlatforms } from '../../../../../models/platforms';

export default function OptionsList() {
  const { donationCurrency } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();
  const [isBrowserExtensionShown, setIsBrowserExtensionShown] = useState(false);
  const [isHardwareWalletShown, setIsHardwareWalletShown] = useState(false);
  const [isOnlineWalletShown, setIsOnlineWalletShown] = useState(false);

  useEffect(() => {
    Object.values(acceptedDonationPlatforms.browserExtension).forEach(
      (platform) => {
        if (platform.includes(donationCurrency)) {
          setIsBrowserExtensionShown(true);
        }
      }
    );

    Object.values(acceptedDonationPlatforms.hardwareWallet).forEach(
      (platform) => {
        if (platform.includes(donationCurrency)) {
          setIsHardwareWalletShown(true);
        }
      }
    );

    Object.values(acceptedDonationPlatforms.onlineWallet).forEach(
      (platform) => {
        if (platform.includes(donationCurrency)) {
          setIsOnlineWalletShown(true);
        }
      }
    );
  }, [donationCurrency]);

  return (
    <form>
      <div className='row options-list'>
        {isOnlineWalletShown && (
          <div className='col-12 col-mb-10 offset-mb-1'>
            <PaymentMethodCard
              body='Connect to Coinbase'
              header='Online Wallet'
              icon={<LanguageIcon color='action' />}
              onClick={() => dispatch(setFormSection(section.ONLINE_WALLET))}
            />
          </div>
        )}

        {isBrowserExtensionShown && (
          <div className='col-12 col-mb-10 offset-mb-1'>
            <PaymentMethodCard
              body='Connect with MetaMask'
              header='Browser Extension'
              icon={<ExtensionIcon color='action' />}
              onClick={() =>
                dispatch(setFormSection(section.BROWSER_EXTENSTION))
              }
            />
          </div>
        )}

        {isHardwareWalletShown && (
          <div className='col-12 col-mb-10 offset-mb-1'>
            <PaymentMethodCard
              body='Trezor, Ledger Wallet, KeepKey'
              header='Hardware'
              icon={<MemoryOutlinedIcon color='action' />}
              onClick={() => dispatch(setFormSection(section.HARDWARE_WALLET))}
            />
          </div>
        )}

        <div className='col-12 col-mb-10 offset-mb-1'>
          <PaymentMethodCard
            body='Copy and paste address string or scan QR code'
            header='QR Code'
            icon={<CropFreeOutlinedIcon color='action' />}
            onClick={() => dispatch(setFormSection(section.QR_CODE))}
          />
        </div>
      </div>
    </form>
  );
}
