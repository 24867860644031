import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaginationButton } from '../../components/buttons';
import {
  DonationFormContent,
  DonationFormFooter,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { PaginationButtonGroup } from '../../components/groups';
import { DonationFormInput } from '../../components/inputs';
import { UserMessage } from '../../components/messages';
import { DonationSteps } from '../../components/stepper';

/* Constants */
import {
  SELECT_CURRENCY,
  PAYMENT_METHOD,
  REVIEW_DONATION,
} from '../../constants/formSections';
import { userMessages as messages } from '../../constants/userMessages';

/* Dispatch */
import {
  setDonationAmount,
  setDonationAmountUSD,
  setFormSection,
  toggleIsDonationUnderReview,
} from '../../actions/donationActions';

/* Libraries */
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import clsx from 'clsx';
import firebase from '../../../../firebase';

/* Models */
import { acceptedDonationCurrencies } from '../../../../models/currencies';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';

export default function DonationAmount() {
  const {
    donationAmount,
    donationAmountUSD,
    donationCurrency,
    formSection,
    isDonationUnderReview,
  } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();

  const [USD, setUSD] = useState(donationAmountUSD);
  const [crypto, setCrypto] = useState(donationAmount);
  const [errorMessage, setErrorMessage] = useState('');
  const [conversionRate, setConversionRate] = useState(0);

  const tickerSymbol = useMemo(() => {
    const { tickerSymbol } = acceptedDonationCurrencies.find(
      (currency) => currency.network === donationCurrency
    );
    return tickerSymbol;
  }, [donationCurrency]);

  const updateConversionRate = useCallback(() => {
    firebase
      .firestore()
      .collection('rates')
      .doc(tickerSymbol)
      .onSnapshot((docSnapshot) => {
        setConversionRate(docSnapshot.data()['value']);
      });
  }, [tickerSymbol]);

  useEffect(() => {
    updateConversionRate();
    const updateInterval = setInterval(() => {
      updateConversionRate();
    }, 5000);

    return () => clearInterval(updateInterval);
  }, [updateConversionRate]);

  const clearFields = () => {
    setUSD(0);
    setCrypto(0);
  };

  const continueToNextSection = (event) => {
    event.preventDefault();
    if (isFormSectionValid()) {
      dispatch(setDonationAmount(parseFloat(crypto)));
      dispatch(setDonationAmountUSD(parseFloat(USD).toFixed(2)));
      if (isDonationUnderReview) {
        dispatch(setFormSection(REVIEW_DONATION));
        dispatch(toggleIsDonationUnderReview());
      } else {
        dispatch(setFormSection(PAYMENT_METHOD));
      }
    }
  };

  const goBackToPreviousSection = () => {
    if (!isDonationAmountTooLow) {
      dispatch(setDonationAmount(parseFloat(crypto)));
      dispatch(setDonationAmountUSD(parseFloat(USD).toFixed(2)));
    }
    if (isDonationUnderReview) {
      dispatch(setFormSection(REVIEW_DONATION));
      dispatch(toggleIsDonationUnderReview());
    } else {
      dispatch(setFormSection(SELECT_CURRENCY));
    }
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    const isUSD = name === 'USD';
    let formattedValue;

    if (isUSD) {
      formattedValue = Number(USD).toFixed(2);
      setUSD(formattedValue);
      setCrypto((Number(formattedValue) * conversionRate).toFixed(8));
    } else {
      formattedValue = Number(crypto).toFixed(8);
      setCrypto(formattedValue);
      setUSD((Number(formattedValue) / conversionRate).toFixed(2));
    }

    isFormSectionValid();
  };


  const isDonationAmountTooLow = () => {
    return Number(USD) < 10;
  };

  const isFormSectionValid = () => {
    let errorMessage = '';

    if (isDonationAmountTooLow()) {
      errorMessage = messages.DONATION_AMOUNT_TOO_LOW;
    }

    setErrorMessage(errorMessage);
    return errorMessage.length === 0;
  };

  const triggerChangeFromCrypto = (value) => {
    setCrypto(value);
    setUSD((Number(value) / conversionRate).toFixed(2));
  };

  const triggerChangeFromUSD = (value) => {
    setUSD(value);
    setCrypto((Number(value) * conversionRate).toFixed(8));
  };

  const updateDonationAmount = (event) => {
    const { name, value } = event.target;
    const pattern = /^[0-9]*\.?[0-9]*$/;
    const isUSD = name === 'USD';

    if (value.match(pattern)) {
      if (value === '') {
        clearFields();
      } else if (isUSD) {
        if (value === '0' || value === '.') {
          if (USD === 0) {
            setUSD('0.');
            setCrypto(Number(0).toFixed(8));
          } else if (USD === '0.') {
            clearFields();
          } else {
            triggerChangeFromUSD(value);
          }
        } else {
          triggerChangeFromUSD(value);
        }
      } else {
        if (value === '0' || value === '.') {
          if (crypto === 0) {
            setCrypto('0.');
            setUSD(Number(0).toFixed(2));
          } else if (crypto === '0.') {
            clearFields();
          } else {
            triggerChangeFromCrypto(value);
          }
        } else {
          triggerChangeFromCrypto(value);
        }
      }
    }

    setErrorMessage('');
  };

  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader onCloseCallback={toggleIsFormOpen}>
            <h1>Enter Donation Amount</h1>
            <DonationSteps activeStep={formSection} />
          </DonationFormHeader>

          <DonationFormContent>
            <form autoComplete='off' noValidate>
              <div className='row'>
                <div
                  className={clsx(
                    'col-12 mb-4 align-items-center',
                    'col-mb-10 offset-mb-1',
                    'col-sm-6 offset-sm-0 mb-sm-0',
                    'col-md-5 offset-md-1'
                  )}>
                  <DonationFormInput
                    icon={<AttachMoneyOutlinedIcon />}
                    id='donation-amount-USD'
                    label='United States Dollar (USD)'
                    name='USD'
                    onBlur={handleBlur}
                    onChange={updateDonationAmount}
                    style={{ maxWidth: '260px' }}
                    value={USD !== 0 ? `${USD}` : ''}
                  />
                </div>

                <div
                  className={clsx(
                    'col-12 align-items-center',
                    'col-mb-10 offset-mb-1',
                    'col-sm-6 offset-sm-0',
                    'col-md-5'
                  )}>
                  <DonationFormInput
                    icon={<AccountBalanceWalletIcon />}
                    id='donation-amount-crypto'
                    label={`${donationCurrency} (${tickerSymbol})`}
                    name='crypto'
                    onBlur={handleBlur}
                    onChange={updateDonationAmount}
                    style={{ maxWidth: '260px' }}
                    value={crypto !== 0 ? `${crypto}` : ''}
                  />
                </div>
              </div>

              <aside className='row'>
                <div className='col-12 col-mb-10 offset-mb-1 text-center'>
                  <span>
                    $1.00 USD = {Number(conversionRate).toFixed(10)}{' '}
                    {tickerSymbol}
                  </span>
                </div>
              </aside>

              {!!errorMessage && (
                <div className='row no-gutters'>
                  <div className='col-12 col-mb-10 offset-mb-1'>
                    <UserMessage message={errorMessage} type='error' />
                  </div>
                </div>
              )}
            </form>
          </DonationFormContent>

          <DonationFormFooter>
            <PaginationButtonGroup>
              <PaginationButton
                onClickHandler={continueToNextSection}
                type='primary'>
                Continue
              </PaginationButton>
              <PaginationButton
                onClickHandler={goBackToPreviousSection}
                type='secondary'>
                Previous
              </PaginationButton>
            </PaginationButtonGroup>
          </DonationFormFooter>
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
