import React from 'react';

/* CSS */
import './index.scss';

/* Libraries */
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';

UserMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export function UserMessage(props) {
  const { message, type } = props;

  const dictionary = {
    error: {
      icon: <ErrorOutlineIcon />,
      label: 'Error',
    },
    info: {
      icon: <InfoOutlinedIcon />,
      label: 'Info',
    },
  };

  return (
    <div className='userMessage_wrapper'>
      <div className={clsx('userMessage', `${type}`)}>
        {dictionary[type].icon}
        <span className='user-message'>
          <b>{`${dictionary[type].label}: `}</b>
          {message}
        </span>
      </div>
    </div>
  );
}
