export const acceptedDonationPlatforms = {
  browserExtension: {
    metamask: ['Ether'],
  },
  hardwareWallet: {
    trezor: ['Bitcoin', 'Litecoin' ],
  },
  onlineWallet: {
    coinbase: [
      'Bitcoin',
      'Celo',
      'Ether',
      'Litecoin',
      'Chainlink',
      'Dogecoin'
    ],
  },
};

//coinbase: Ripple, Stellar, EOS
//Trezor: Ripple, Stellar
