import React from 'react';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import LinearScaleOutlinedIcon from '@material-ui/icons/LinearScaleOutlined';
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined';
import NatureOutlinedIcon from '@material-ui/icons/NatureOutlined';

export function useProjectHeader() {
  return {
    header: {
      title: 'Chocolate 4 All,',
      location: 'Honduras',
      subtitle: 'Fostering sustainable cacao markets',
      description:
        'Cacao farmers in Honduras face many barriers to entering the formal economy.',
    },
    stats: {
      location: 'Olancho, Honduras',
      products: 'Cacao',
      activeSince: '2019',
    },
  };
}

export function useProjectObjectives() {
  return [
    {
      id: 1,
      header: 'Strengthen Business Practices',
      description: `Develop high-functioning businesses through organizational strengthening, improved business management processes and supply chain linkages.`,
      icon: <BusinessCenterOutlinedIcon style={{color:'#fff', width:'2rem',height:'auto'}}/>
    },
    {
      id: 2,
      header: 'Implement Empowering Technologies',
      description: `Increase cacao productivity and resilience by implementing sustainable agriculture practices and adopting new technologies.`,
      icon: <NatureOutlinedIcon style={{color:'#fff', width:'2rem',height:'auto'}}/>
    },
    {
      id: 3,
      header: 'Increase Scalability',
      description: `Increase knowledge management for scalability at the regional level.`,
      icon: <ShowChartOutlinedIcon style={{color:'#fff', width:'2rem',height:'auto'}}/>
    },
    {
      id: 4,
      header: 'Enable Supply Chain Traceability',
      description: `Enhance supply chain traceability and access to financial services through technological innovations.`,
      icon: <LinearScaleOutlinedIcon style={{color:'#fff', width:'2rem',height:'auto'}}/>
    },
  ];
}
