import React from 'react';

/* Libraries */
import PropTypes from 'prop-types';

/* Styles */
import './Cards.scss';

PaymentMethodCard.propTypes = {
  body: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
};

ComingSoonCard.propTypes = {
  header: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}

export function PaymentMethodCard(props) {
  const { body, header, icon, onClick } = props;

  return (
    <div className='paymentMethodCard__wrapper'>
      <div className='flex-row paymentMethodCard' onClick={onClick}>
        <figure className='flex-column center card-icon'>{icon}</figure>
        <div className='flex-column card-content'>
          <h3>{header}</h3>
          <p>{body}</p>
        </div>
      </div>
    </div>
  );
}

export function ComingSoonCard(props) {
  const {header, icon } = props;

  return (
    <div className='paymentMethodCard__wrapper'>
      <div className='flex-row comingSoonCard' >
        <figure className='flex-column center card-icon'>{icon}</figure>
        <div className='flex-column card-content'>
          <h3>{header}</h3>
          <p>Coming soon!</p>
        </div>
      </div>
    </div>
  );
}
