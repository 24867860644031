import React from 'react';

/* CSS */
import './Tooltips.scss';

/* Libraries */
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import PropTypes from 'prop-types';

DonationFormTooltip.propTypes = {
  tooltip: PropTypes.shape({
    position: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export function DonationFormTooltip(props) {
  const { tooltip } = props;

  const dictionary = {
    bottom: 'bottom-ttip',
    top: 'top-ttip',
  };

  return (
    <div className='donationFormTooltip__container'>
      <HelpOutlineIcon />
      <div className={dictionary[tooltip.position]}>{tooltip.text}</div>
    </div>
  );
}
