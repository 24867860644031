const BROWSER_EXTENSTION = 'BROWSER_EXTENSION';
const DONATION_AMOUNT = 'DONATION_AMOUNT';
const DONATION_CONFIRMATION = 'DONATION_CONFIRMATION';
const CUSTOMIZATIONS = 'CUSTOMIZATIONS';
const DONOR_INFORMATION = 'DONOR_INFORMATION';
const HARDWARE_WALLET = 'HARDWARE';
const ONLINE_WALLET = 'ONLINE_WALLET';
const PAYMENT_METHOD = 'PAYMENT_METHOD';
const PROVIDE_FEEDBACK = 'PROVIDE_FEEDBACK';
const QR_CODE = 'QR_CODE';
const REVIEW_DONATION = 'REVIEW_DONATION';
const SELECT_CURRENCY = 'SELECT_CURRENCY';

export {
  BROWSER_EXTENSTION,
  DONATION_AMOUNT,
  DONATION_CONFIRMATION,
  CUSTOMIZATIONS,
  DONOR_INFORMATION,
  HARDWARE_WALLET,
  ONLINE_WALLET,
  PAYMENT_METHOD,
  PROVIDE_FEEDBACK,
  QR_CODE,
  REVIEW_DONATION,
  SELECT_CURRENCY,
};
