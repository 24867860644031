import React from 'react';

/* CSS */
import './index.scss';

/* Libraries */
import clsx from 'clsx';
import PropTypes from 'prop-types';

export function DonationSteps(props) {
  const { activeStep } = props;

  const steps = {
    SELECT_CURRENCY: {
      id: 1,
      label: 'Select Currency',
    },
    DONATION_AMOUNT: {
      id: 2,
      label: 'Enter Donation Amount',
    },
    PAYMENT_METHOD: {
      id: 3,
      label: 'Select Payment Method',
    },
    REVIEW_DONATION: {
      id: 4,
      label: 'Review Donation',
    },
  };

  /**
   *
   */
  const getStepNumberClasses = (id) => {
    const isActive = Object.values(steps)[id - 1].id <= steps[activeStep].id;
    return clsx('step-number', isActive && 'active-step');
  };

  /**
   *
   */
  const handleStepClick = () => {};

  return (
    <div className='donation-steps'>
      {Object.keys(steps).map((key) => {
        const id = steps[key].id;
        const label = steps[key].label;
        return (
          <div key={id} className='step' onClick={handleStepClick}>
            <div className={getStepNumberClasses(id, label)}>
              <span>{id}</span>
            </div>
            <span className='step-label'>{label}</span>
          </div>
        );
      })}
    </div>
  );
}

DonationSteps.propTypes = {
  activeStep: PropTypes.string.isRequired,
};
