const UNVERIFIED = 'UNVERIFIED';
const PROCESSING = 'PROCESSING';
const COMPLETED = 'COMPLETED';
const VERIFIED = 'VERIFIED';

export const verificationStates = {
  UNVERIFIED,
  PROCESSING,
  COMPLETED,
  VERIFIED,
};
