import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaginationButton } from '../../../components/buttons';
import { PaginationButtonGroup } from '../../../components/groups';
import { DonationFormInput } from '../../../components/inputs';
import { UserMessage } from '../../../components/messages';
import { DonationFormTooltip } from '../../../components/tooltips';

/* Constants */
import {
  PAYMENT_METHOD,
  REVIEW_DONATION,
} from '../../../constants/formSections';
import { userMessages as messages } from '../../../constants/userMessages';
import { verificationStates } from '../../../constants/verificationStates';

/* Dispatch */
import {
  setDonorWalletAddress,
  setFormSection,
  toggleIsDonorWalletAddressVerified,
} from '../../../actions/donationActions';

/* Libraries */
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import clsx from 'clsx';

export default function QRCode() {
  const {
    donationCurrency,
    donorWalletAddress,
    isDonorAnonymous,
    isDonorWalletAddressVerified,
    projectQRCode,
    projectWalletAddress,
    projectMemoKey,
    projectMemoQRCode,
  } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [verificationProcessPhase, setVerificationProcessPhase] = useState(
    isDonorWalletAddressVerified
      ? verificationStates.VERIFIED
      : verificationStates.UNVERIFIED
  );

  const successMessageTimer = useRef();
  const verificationProcessTimer = useRef();

  const continueToNextSection = () => {
    dispatch(setFormSection(REVIEW_DONATION));
  };

  const copyInputText = (inputElement) => {
    // Copy the text from the input
    inputElement.select();
    document.execCommand('copy');

    // Un-hilight the text
    document.getSelection().removeAllRanges();

    // Compatibility with IE11
    document.getSelection().addRange(document.createRange());
  };

  const copyProjectAddress = () => {
    copyInputText(document.getElementById('project-address'));
  };

  const copyProjectMemoKey = () => {
    copyInputText(document.getElementById('project-memo-key'));
  };

  const getDonorWalletAddressIcon = () => {
    return isDonorWalletAddressVerified ? (
      <VerifiedUserOutlinedIcon
        className='input-icon'
        style={{ color: 'rgb(75, 181, 67)' }}
      />
    ) : (
      <HowToRegOutlinedIcon
        className='input-icon'
        style={{ color: 'rgb(177, 177, 177)' }}
      />
    );
  };

  // Todo: making a loading overlay panel with progress spinner
  const getDonorWalletAddressStyles = () => {
    // Apply dark overlay when processing
    if (verificationProcessPhase === verificationStates.PROCESSING) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
        borderRadius: '6px',
        padding: '7px',
      };
    }

    // Apply opacity when completed or verified
    else if (
      verificationProcessPhase === verificationStates.COMPLETED ||
      verificationProcessPhase === verificationStates.VERIFIED
    ) {
      return { opacity: '0.3' };
    }

    return {};
  };

  const getProjectMemoKeyTooltip = () => {
    return {
      position: 'top',
      text: messages.PROJECT_MEMO_KEY_TOOLTIP,
    };
  };

  const getProjectWalletAddressTooltip = () => {
    return {
      position: 'top',
      text: messages.PROJECT_WALLET_ADDRESS_TOOLTIP,
    };
  };

  const getDonorWalletAddressTooltip = () => {
    return {
      position: 'top',
      text: messages.DONOR_WALLET_ADDRESS_TOOLTIP,
    };
  };

  const getInstructions = () => {
    // Only prompt for verification when non-anon and unverified
    if (isDonorAnonymous || isDonorWalletAddressVerified) {
      return messages.QR_STEP_TWO;
    } else {
      return messages.QR_STEP_ONE;
    }
  };

  const goBackToPreviousSection = () => {
    setErrorMessage('');

    // Cancel process if running
    stopVerificationProcess();
    !isDonorWalletAddressVerified &&
      setVerificationProcessPhase(verificationStates.UNVERIFIED);

    // Go back
    dispatch(setFormSection(PAYMENT_METHOD));
  };

  const isDonorWalletAddressValid = () => {
    let errorMessage = '';

    // If the field is empty
    if (donorWalletAddress.length === 0) {
      errorMessage = messages.EMPTY_DONOR_WALLET_ADDRESS;
    }

    setErrorMessage(errorMessage);
    return errorMessage.length === 0;
  };

  const stopVerificationProcess = () => {
    clearTimeout(verificationProcessTimer.current);
    clearTimeout(successMessageTimer.current);
    setVerificationProcessPhase(verificationStates.UNVERIFIED);
  };

  const startVerificationProcess = () => {
    if (isDonorWalletAddressValid()) {
      setVerificationProcessPhase(verificationStates.PROCESSING);
      verificationProcessTimer.current = setTimeout(() => {
        // Check Ether addresses
        if (
          donationCurrency === 'Ether' &&
          !donorWalletAddress.includes('0x')
        ) {
          stopVerificationProcess();
          setErrorMessage(messages.FAILED_ADDRESS_VERIFICATION);
        }

        // Trigger success message
        else {
          setVerificationProcessPhase(verificationStates.COMPLETED);
          clearTimeout(verificationProcessTimer.current);
          successMessageTimer.current = setTimeout(() => {
            // Verify user and continue
            dispatch(toggleIsDonorWalletAddressVerified());
            setVerificationProcessPhase(verificationStates.VERIFIED);
            clearTimeout(successMessageTimer.current);
          }, 1000);
        }
      }, 5000);
    }
  };

  const updateDonorWalletAddress = (event) => {
    // Reset verification process when the user changes the address
    stopVerificationProcess();
    isDonorWalletAddressVerified &&
      dispatch(toggleIsDonorWalletAddressVerified());

    // Update form
    setErrorMessage('');
    dispatch(setDonorWalletAddress(event.target.value));
  };

  return (
    <>
      <form
        autoComplete='off'
        noValidate
        onSubmit={(event) => event.preventDefault()}>
        <div className='row no-gutters'>
          <div className={clsx('col-12', 'col-mb-10 offset-mb-1')}>
            <UserMessage message={getInstructions()} type='info' />
          </div>
        </div>

        {!isDonorAnonymous && (
          <div className='row no-gutters'>
            <div
              className={clsx('col-11 pr-3', 'col-mb-9 offset-mb-1 pr-mb-0')}>
              <DonationFormInput
                icon={getDonorWalletAddressIcon()}
                id='donor-sending-address'
                label='My sending address'
                onChange={updateDonorWalletAddress}
                style={getDonorWalletAddressStyles()}
                value={donorWalletAddress}
              />
            </div>
            <div className='col-1 ml-auto flex-column center'>
              <DonationFormTooltip tooltip={getDonorWalletAddressTooltip()} />
            </div>
          </div>
        )}

        {(verificationProcessPhase === verificationStates.PROCESSING ||
          verificationProcessPhase === verificationStates.COMPLETED) && (
          <div className='row'>
            <div className='col-12 align-items-center'>
              <div className='text-center'>
                {verificationProcessPhase === verificationStates.PROCESSING ? (
                  <Fade in style={{ transitionDelay: '500ms' }} unmountOnExit>
                    <CircularProgress />
                  </Fade>
                ) : (
                  <span>Success!</span>
                )}
              </div>
            </div>
          </div>
        )}

        {(isDonorAnonymous || isDonorWalletAddressVerified) && (
          <>
            <div className='row no-gutters'>
              <div
                className={clsx('col-11 pr-3', 'col-mb-9 offset-mb-1 pr-mb-0')}>
                <DonationFormInput
                  actionIcon={<FileCopyIcon onClick={copyProjectAddress} />}
                  id='project-address'
                  label='Project Wallet Address'
                  readOnly={true}
                  value={projectWalletAddress}
                />
              </div>
              <div className='col-1 ml-auto flex-column center'>
                <DonationFormTooltip
                  tooltip={getProjectWalletAddressTooltip()}
                />
              </div>
            </div>

            <div className='row no-gutters'>
              <div
                className='col-10 offset-1'
                style= {{textAlign: 'center'}}>
                <figure>
                  <img
                    src={projectQRCode}
                    alt='Project wallet address QR code'
                    style={{maxWidth : '200px'}}
                  />
                </figure>
              </div>
            </div>

            {!!projectMemoKey && !!projectMemoQRCode && (
              <>
                <div className='row no-gutters'>
                  <div
                    className={clsx(
                      'col-11 pr-3',
                      'col-mb-9 offset-mb-1 pr-mb-0'
                    )}>
                    <DonationFormInput
                      actionIcon={<FileCopyIcon onClick={copyProjectMemoKey} />}
                      id='project-memo-key'
                      label='Project Memo Key'
                      readOnly={true}
                      value={projectMemoKey}
                    />
                  </div>
                  <div className='col-1 ml-auto flex-column center'>
                    <DonationFormTooltip tooltip={getProjectMemoKeyTooltip()} />
                  </div>
                </div>

                <div className='row no-gutters'>
                  <div className='col-10 offset-1'>
                    <figure>
                      <img src={projectMemoQRCode} alt='Project memo QR code' />
                    </figure>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {!!errorMessage && (
          <div className='row no-gutters'>
            <div className='col-12 col-mb-10 offset-mb-1'>
              <UserMessage message={errorMessage} type='error' />
            </div>
          </div>
        )}
      </form>

      <footer>
        <PaginationButtonGroup>
          {(isDonorAnonymous || isDonorWalletAddressVerified) && (
            <PaginationButton
              onClickHandler={continueToNextSection}
              type='primary'>
              Review Donation
            </PaginationButton>
          )}
          {!isDonorAnonymous &&
            verificationProcessPhase === verificationStates.UNVERIFIED && (
              <PaginationButton
                onClickHandler={startVerificationProcess}
                type='success'>
                Confirm
              </PaginationButton>
            )}
          {!isDonorAnonymous &&
            verificationProcessPhase === verificationStates.PROCESSING && (
              <PaginationButton
                onClickHandler={stopVerificationProcess}
                type='danger'>
                Cancel
              </PaginationButton>
            )}
          <PaginationButton
            onClickHandler={goBackToPreviousSection}
            type='secondary'>
            Previous
          </PaginationButton>
        </PaginationButtonGroup>
      </footer>
    </>
  );
}
