import React, { useEffect, useState } from 'react';

/* CSS */
import './ProjectDetails.scss';

/* Libraries */
import PaymentIcon from '@material-ui/icons/Payment';
import firebase from '../../../../firebase.js';
import PropTypes from 'prop-types';

/* Resources */
import images from '../../../../assets/images';

ProjectDetails.propTypes = {
  openDonationModal: PropTypes.func.isRequired,
};

export default function ProjectDetails(props) {
  const { openDonationModal } = props;
  const [goalAmount, setGoalAmount] = useState(0);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);

  const fundraisingBenchmarks = [
    {
      weight: '20%',
      label: '20%',
    },
    {
      weight: '20%',
      label: '50%',
    },
    {
      weight: '20%',
      label: '80%',
    },
    {
      weight: '20%',
      label: '100%',
    },
  ];

  useEffect(() => {
    const projectId = 1;

    firebase
      .database()
      .ref(`projects/${projectId}/goalAmount`)
      .on('value', (snapshot) => {
        setGoalAmount(snapshot.val());
      });

    firebase
      .database()
      .ref(`projects/${projectId}/totalDonationAmount`)
      .on('value', (snapshot) => {
        setTotalDonationAmount(snapshot.val());
      });
  }, []);

  /**
   *
   */
  const getProgressBarHeight = () => {
    return Math.ceil(parseFloat((totalDonationAmount / goalAmount) * 100));
  };

  return (
    <section className='projectPage__section'>
      <article className='aboutTheProject__container'>
        <h2>About the Project</h2>
        <div className='article-content'>
          <img src={images.cacao} alt='Project Photograph' />
          <div className='second'>
            <img src={images.whatsapp} className='second' alt='Project Photograph' />
          </div>
          <p>
            Small-scale cacao farmers face a number of challenges that increase 
            their economic vulnerability, such as plant varieties that are less 
            resistant to pests and diseases and inefficient crop management due 
            to inadequate technology.
          </p>
          <p>
            To offset these challenges, the Chocolate4All works alongside cacao
             producers to strengthen the sector’s competitiveness at regional and
              national levels by promoting social innovation and new technologies.
          </p>
          <p>
            Heifer will improve the quality of life of 1,000 small-scale cacao 
            farmers in Olancho, Honduras by increasing value and profitability in 
            cacao production, enabling families to close the living income gap and 
            increase the resilience of the cacao agroforestry system.
          </p>
          <p>
            The project provides innovative solutions that will improve access to 
            high-quality, differentiated markets and reduce vulnerability to climate 
            change. One avenue for this is blockchain technology that manages 
            traceability to provide open access to cacao supply chain data. Drones, 
            sensor and simulation software will also improve crop management and yield.
          </p>
        </div>
      </article>
      
    </section>
  );
}
