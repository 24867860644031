import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { ComingSoonCard, PaymentMethodCard } from '../../../components/cards';
import { UserMessage } from '../../../components/messages';
import { DonationFormTooltip } from '../../../components/tooltips';

/* Constants */
import * as section from '../../../constants/formSections';

/* Dispatch */
import {
  setFormSection,
  setOnlineWalletPlatform,
} from '../../../actions/donationActions';

/* Libraries */
import TrezorConnect from 'trezor-connect';

/* Resources */
import images from '../../../../../assets/images';

TrezorConnect.manifest({
  email: 'info@copiedcode.com',
  appUrl: 'https://www.copiedcode.com',
});

export default function HardwareWallet() {
  const {
    donationAmount,
    projectWalletAddress,
    donationCurrency,
  } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const connectTrezor = async () => {
    let currency = 'btc';

    switch (donationCurrency) {
      case 'Bitcoin':
        currency = 'btc';
        break;
      case 'Litecoin':
        currency = 'ltc';
        break;
      case 'Stellar Lumen':
        currency = 'xlm';
        break;
      default:
        currency = 'btc';
    }

    const amount = (donationAmount * 100000000).toString();
    const result = await TrezorConnect.signTransaction({
      inputs: [
        {
          address_n: [
            (44 | 0x80000000) >>> 0,
            (0 | 0x80000000) >>> 0,
            (2 | 0x80000000) >>> 0,
            1,
            0,
          ],
          prev_index: 0,
          prev_hash:
            'b035d89d4543ce5713c553d69431698116a822c57c03ddacf3f04b763d1999ac',
        },
      ],
      outputs: [
        {
          address: projectWalletAddress,
          amount: amount,
          script_type: 'PAYTOADDRESS',
        },
      ],
      coin: currency,
    });

    if (result.payload.error !== '' && result.payload.error !== null) {
      setErrorMessage(result.payload.error);
    } else {
      if (errorMessage === '') {
        dispatch(setOnlineWalletPlatform('trezor'));
        dispatch(setFormSection(section.REVIEW_DONATION));
      }
    }
  };

  const getTrezorIcon = () => {
    return <img src={images.trezor} height='100%' width='100%' alt='Trezor' />;
  };

  const getLedgerIcon = () => {
    return <img src={images.ledger} height='100%' width='100%' alt='Ledger' />;
  };

  const getKeepkeyIcon = () => {
    return (
      <img src={images.keepkey} height='100%' width='100%' alt='KeepKey' />
    );
  };

  // Todo: Create a meaningful tooltip
  const getTrezorTooltip = () => {
    return {
      position: 'bottom',
      text: `Connect to Trezor.`,
    };
  };

  return (
    <form>
      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
          <PaymentMethodCard
            body='Connect to Trezor'
            header='Trezor'
            icon={getTrezorIcon()}
            onClick={connectTrezor}
          />
        </div>
      </div>

      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
        <PaymentMethodCard
            body='Connect to Ledger'
            header='Ledger'
            icon={getLedgerIcon()}
            onClick={() =>  setErrorMessage("No Ledger device found.")}
          />
        </div>
      </div>

      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
        <PaymentMethodCard
            body='Connect to KeepKey'
            header='KeepKey'
            icon={getKeepkeyIcon()}
            onClick={() => setErrorMessage("No KeepKey device found.")}
          />
        </div>
      </div>

      {!!errorMessage && (
        <div className='row no-gutters'>
          <div className='col-12 col-mb-10 offset-mb-1'>
            <UserMessage message={errorMessage} type='error' />
          </div>
        </div>
      )}
    </form>
  );
}
