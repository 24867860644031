// Todo: use these strings to create localization (i18n)
const ANONYMOUS_CHECKBOX_TOOLTIP = `Anonymous donations can't receive updates, tax receipts, or refunds.`;
const COINBASE_TOOLTIP = `Login to Coinbase and verify your account connection to the donation tracker.`;
const DONATION_AMOUNT_TOO_LOW = `Minimum donation is $10.00 USD.`;
const DONOR_LEGAL_NAME_TOOLTIP = `Please supply the full legal name to be displayed on tax filing documents.`;
const DONOR_WALLET_ADDRESS_TOOLTIP = `This information verifies the donor and allows you to receive impact updates or tax receipts.`;
const DISABLED_DISPLAY_NAME_TOOLTIP = `Your name will appear as "Anonymous Donor" on the project donation table.`;
const DISABLED_TAX_RECEIPT_TOOLTIP = `Heifer can't send the donor tax receipts if you are anonymous.`;
const DISABLED_UPDATES_TOOLTIP = `Heifer can't send impact updates if you are anonymous.`;
const DISABLED_RECUR_TOOLTIP = `You can't set up recurring donations if you are anonymous.`;
const EMPTY_DISPLAY_NAME = `Please provide a display name.`;
const EMPTY_DONATION_CURRENCY = `You must select a currency.`;
const EMPTY_DONOR_WALLET_ADDRESS = `Address string cannot be empty`;
const EMPTY_EMAIL_ADDRESS = `Please provide an email address.`;
const EMPTY_LEGAL_NAME = `Full legal name must include first name and surname.`;
const ENABLED_DISPLAY_NAME_TOOLTIP = `This name will appear on the project donation table.`;
const ENABLED_TAX_RECEIPT_TOOLTIP = `Heifer can send the donor tax receipts.`;
const ENABLED_UPDATES_TOOLTIP = `Heifer can send you impact updates for this project.`;
const ENABLED_RECUR_TOOLTIP = `You can choose to give monthly to this project. We'll send an email with a ready-to-go donation link every month.`;
const FAILED_ADDRESS_VERIFICATION = `Could not verify address string`;
const FEEDBACK_NOT_SUBMITTED = `Feedback has not been submitted! Closing this page will not save any input, and you will not be able to return.`;
const INVALID_EMAIL_ADDRESS = `Invalid email address.`;
const INVALID_RATING = `Invalid rating. Please rate from 1 to 5 stars.`;
const METAMASK_TOOLTIP = `Confirm your donation through the MetaMask browser pop-up.`;
const PROJECT_MEMO_KEY_TOOLTIP = `This is a tooltip for the memo key.`;
const PROJECT_WALLET_ADDRESS_TOOLTIP = `This is the unique digital wallet address for this project. Copy the string and paste it in the recipient address field.`;
const QR_STEP_ONE = `First, verify your sending address by typing or pasting below.`;
const QR_STEP_TWO = `Copy and paste the address string or scan the QR code below.`;


export const userMessages = {
  ANONYMOUS_CHECKBOX_TOOLTIP,
  COINBASE_TOOLTIP,
  DONATION_AMOUNT_TOO_LOW,
  DONOR_LEGAL_NAME_TOOLTIP,
  DONOR_WALLET_ADDRESS_TOOLTIP,
  DISABLED_DISPLAY_NAME_TOOLTIP,
  DISABLED_TAX_RECEIPT_TOOLTIP,
  DISABLED_UPDATES_TOOLTIP,
  DISABLED_RECUR_TOOLTIP,
  EMPTY_DISPLAY_NAME,
  EMPTY_DONATION_CURRENCY,
  EMPTY_DONOR_WALLET_ADDRESS,
  EMPTY_EMAIL_ADDRESS,
  EMPTY_LEGAL_NAME,
  ENABLED_DISPLAY_NAME_TOOLTIP,
  ENABLED_TAX_RECEIPT_TOOLTIP,
  ENABLED_UPDATES_TOOLTIP,
  ENABLED_RECUR_TOOLTIP,
  FAILED_ADDRESS_VERIFICATION,
  FEEDBACK_NOT_SUBMITTED,
  INVALID_EMAIL_ADDRESS,
  INVALID_RATING,
  METAMASK_TOOLTIP,
  PROJECT_MEMO_KEY_TOOLTIP,
  PROJECT_WALLET_ADDRESS_TOOLTIP,
  QR_STEP_ONE,
  QR_STEP_TWO,
};
