/* Resources */
import images from '../assets/images';

/* Conversion rates assume $1.00 USD
 * Ex) BTC : USD = 0.00014 : 1.00
 */
export const acceptedDonationCurrencies = [
  {
    network: 'Bitcoin',
    conversionRate: 0.00014,
    tickerSymbol: 'BTC',
    walletAddress: '36sundnPJaNsU2whBxaCfxTb4JTwvNUvsz',
    qrCode: images.bitcoinqr,
    memoKey: null,
    memoQrCode: null,
  },
  {
    network: 'Celo',
    conversionRate: 0.31152182,
    tickerSymbol: 'CGLD',
    walletAddress: '0xc14cd1Eb171B4BDF2e8eDb218D7AABa896c3A8E6',
    qrCode: images.celoqr,
    memoKey: null,
    memoQrCode: null,
  },
  {
    network: 'Ether',
    conversionRate: 0.0056,
    tickerSymbol: 'ETH',
    walletAddress: '0xE41230578f7369D1741fec12423DC766474d5a2F',
    qrCode: images.ethereumqr,
    memoKey: null,
    memoQrCode: null,
  },
  {
    network: 'Litecoin',
    conversionRate: 0.024,
    tickerSymbol: 'LTC',
    walletAddress: 'MEDJnqNLyX2KV89dsoGyD59oQk7TzYSVqR',
    qrCode: images.litecoinqr,
    memoKey: null,
    memoQrCode: null,
  },
  {
    network: 'EOS',
    conversionRate: 0.378787,
    tickerSymbol: 'EOS',
    walletAddress: 'coinbasebase',
    qrCode: images.eosqr,
    memoKey: '3392254972',
    memoQrCode: images.eosmemo,
  },
  {
    network: 'Stellar Lumen',
    conversionRate: 18.805976,
    tickerSymbol: 'XLM',
    walletAddress: 'GDQP2KPQGKIHYJGXNUIYOMHARUARCA7DJT5FO2FFOOKY3B2WSQHG4W37',
    qrCode: images.stellarqr,
    memoKey: '1177713360',
    memoQrCode: images.stellarmemo,
  },
  {
    network: 'ChainLink',
    conversionRate: 0.0681,
    tickerSymbol: 'LINK',
    walletAddress: '0xbb6948cdC5d429c95fF916a916b3d6fcbcaD79F8',
    qrCode: images.linkqr,
  },
  {
    network: 'Gemini Dollars',
    conversionRate: 1.0,
    tickerSymbol: 'GUSD',
    walletAddress: '0xf50C1E7Fe82325FE88A3213Af7b23181083FeE2e',
    qrCode: images.geminiqr,
  },
  {
    network: 'DogeCoin',
    conversionRate: 1.0,
    tickerSymbol: 'DOGE',
    walletAddress: 'DLEpmJpHKoiRMu9bczugGHXGr45rfCMzR4',
    qrCode: images.dogeqr,
  },

];
