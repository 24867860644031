import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyAeKT18hLBtTUEgiCKxkw8rCebjHEtltFc',
  authDomain: 'heifer-af43d.firebaseapp.com',
  databaseURL: 'https://heifer-af43d.firebaseio.com',
  projectId: 'heifer-af43d',
  storageBucket: 'heifer-af43d.appspot.com',
  messagingSenderId: '119156784820',
  appId: '1:119156784820:web:7419170d2c0055f74561e1'
};

firebase.initializeApp(config);
export default firebase;
