import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* Components */
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { ActionButton, PaginationButton } from '../../components/buttons';
import {
  DonationFormContent,
  DonationFormFooter,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { UserMessage } from '../../components/messages';
import { StarRating } from '../../components/rating';
import { TextArea } from '../../components/textarea';

/* Constants */
import { userMessages as messages } from '../../constants/userMessages';

/* Icons */
import CheckIcon from '@material-ui/icons/Check';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';

/* Styles */
import './ProvideFeedback.scss';

export default function ProvideFeedback() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackAPI, setFeedbackAPI] = useState({
    requesting: false,
    submitted: false,
  });
  const [rating, setRating] = useState('0.0');
  const [infoMessage, setInfoMessage] = useState('');

  const closeDonationForm = (closeForm) => {
    // Warn user if feedback is not submitted
    if (!feedbackAPI.submitted) {
      setInfoMessage(
        <>
          {messages.FEEDBACK_NOT_SUBMITTED} <a href='/'>Close Page</a>
        </>
      );
    } else {
      closeForm();
      history.push('/');
    }
  };

  const sendFeedback = async (stars, feedback) => {
    import('axios').then((axios) => {
      axios
        .post(
          'https://81rfbqicud.execute-api.us-east-2.amazonaws.com/dev/feedback',
          {
            stars,
            feedback,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        )
        .then(() => {
          setFeedbackAPI({ requesting: false, submitted: true });
        })
        .catch((error) => {
          console.error(error);
          setFeedbackAPI({ requesting: false, submitted: false });
          setErrorMessage(
            'Failed to submit feedback. Check your network connection then try again.'
          );
        });
    });
  };

  const isFeedbackValid = () => {
    let errorMessage = '';

    if (Number(rating) === 0) {
      errorMessage = messages.INVALID_RATING;
    }

    setErrorMessage(errorMessage);
    return errorMessage.length === 0;
  };

  const onStarSelect = (rate) => {
    setErrorMessage('');
    setRating(parseFloat(rate).toFixed(1));
  };

  const submitFeedback = () => {
    setInfoMessage('');

    if (isFeedbackValid()) {
      setFeedbackAPI({ ...feedbackAPI, requesting: true });
      sendFeedback(rating, document.getElementById('feedback-comments').value);
    }
  };

  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader
            onCloseCallback={() => closeDonationForm(toggleIsFormOpen)}>
            <h1>Provide Feedback</h1>
            <p>
              Thank you for your contribution to this flagship project. To
              better the donation experience for other donors, we would like to
              receive your feedback.
            </p>
          </DonationFormHeader>

          <DonationFormContent>
            <div className='flex-row center rating-display'>
              <h3>{rating}</h3>
              <div className='flex-column center vertical-center'>
                <span className='caption'>Out of</span>
                <br />
                <span className='caption'>5 stars</span>
              </div>
            </div>

            <form autoComplete='off' noValidate>
              <div className='flex-column center vertical-center'>
                <StarRating
                  onStarSelect={(rate) => {
                    onStarSelect(rate);
                  }}
                />
              </div>

              <div className='flex-column'>
                <TextArea id='feedback-comments' label='Comments' />
                <div className='flex-row end'>
                  <ActionButton
                    onClickHandler={submitFeedback}
                    type={feedbackAPI.submitted ? 'success' : 'action'}>
                    {feedbackAPI.submitted ? (
                      <span className='flex-row vertical-center'>
                        Submitted
                        <CheckIcon />
                      </span>
                    ) : (
                      'Submit'
                    )}
                  </ActionButton>
                </div>
              </div>

              {feedbackAPI.requesting && (
                <div className='flex-row center'>
                  <Fade in style={{ transitionDelay: '500ms' }} unmountOnExit>
                    <CircularProgress />
                  </Fade>
                </div>
              )}

              {!!errorMessage && (
                <div className='row no-gutters'>
                  <div className='col-12 col-mb-10 offset-mb-1'>
                    <UserMessage message={errorMessage} type='error' />
                  </div>
                </div>
              )}

              {!!infoMessage && (
                <div className='row no-gutters'>
                  <div className='col-12 col-mb-10 offset-mb-1'>
                    <UserMessage message={infoMessage} type='info' />
                  </div>
                </div>
              )}
            </form>
          </DonationFormContent>

          <DonationFormFooter>
            <PaginationButton
              onClickHandler={() => closeDonationForm(toggleIsFormOpen)}
              type='secondary'>
              Return to Project
            </PaginationButton>
          </DonationFormFooter>
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
