import React, { useState } from 'react';

/* Libraries */
import PropTypes from 'prop-types';

/* Styles */
import './index.scss';

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
};

export function TextArea(props) {
  const { id, label, maxLength, readOnly } = props;
  const [content, setContent] = useState('');

  // Default character limit for the textarea
  const defaultLength = 524288;

  const handleInput = (event) => {
    setContent(event.target.value);
  };

  return (
    <div className='textarea-wrapper'>
      {!!label && <span className='textarea-label'>{label}</span>}
      <textarea
        autoComplete='off'
        id={id}
        onInput={handleInput}
        maxLength={maxLength ? maxLength : defaultLength}
        readOnly={readOnly ? readOnly : false}></textarea>
      {maxLength && (
        <span className='textarea-limit'>
          <span>{content.length}</span>
          {' out of '}
          <span>{maxLength}</span>
        </span>
      )}
    </div>
  );
}
