import React, { useState } from 'react';

/* CSS */
import './AdminPage.css';

export default function AdminPage() {
  const [form, setForm] = useState({
    projectName: '',
    projectDescription: '',
    projectGoal: '',
    projectTimespan: '',
  });

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <section>
      <header className='portfolio-header'>
        <h1 className='display-3 text-center'>Heifer Admin</h1>
      </header>

      <div className='admin-content'>
        <div className='col-8 offset-2'>
          <h1>Create Project</h1>
          <div className='create-project-form'>
            <form
              autoComplete='off'
              onSubmit={(event) => event.preventDefault()}
            >
              <div className='row m-0 form-field'>
                <div className='col-10 offset-1'>
                  <div className='input-container'>
                    <input
                      id='project-name'
                      type='text'
                      name='projectName'
                      value={form.projectName}
                      onChange={onInputChange}
                      required
                    />
                    <label htmlFor='project-name'>Project Name</label>
                    <span></span>
                  </div>
                </div>
              </div>

              <div className='row m-0 form-field'>
                <div className='col-10 offset-1'>
                  <div className='textarea-container'>
                    <textarea
                      id='project-description'
                      type='text'
                      name='projectDescription'
                      value={form.projectDescription}
                      onChange={onInputChange}
                      required
                    />
                    <label htmlFor='project-description'>
                      Project Description
                    </label>
                  </div>
                </div>
              </div>

              <div className='row m-0 form-field'>
                <div className='col-5 offset-1'>
                  <div className='input-container'>
                    <input
                      id='project-goal'
                      type='text'
                      name='projectGoal'
                      value={form.projectGoal}
                      onChange={onInputChange}
                      required
                    />
                    <label htmlFor='project-goal'>Goal Donation Amount</label>
                    <span></span>
                  </div>
                </div>

                <div className='col-5'>
                  <div className='input-container'>
                    <input
                      id='project-timespan'
                      type='text'
                      name='projectTimespan'
                      value={form.projectTimespan}
                      onChange={onInputChange}
                      required
                    />
                    <label htmlFor='project-timespan'>Anything you want!</label>
                    <span></span>
                  </div>
                </div>
              </div>

              <button className='col-6 offset-3 btn btn-success'>Create</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
