import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaginationButton } from '../../components/buttons';
import {
  DonationFormContent,
  DonationFormFooter,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { DonationSteps } from '../../components/stepper';
import BrowserExtension from './browser-extension/BrowserExtension';
import HardwareWallet from './hardware-wallet/HardwareWallet';
import OnlineWallet from './online-wallet/OnlineWallet';
import OptionsList from './options-list/OptionsList';
import QRCode from './qr-code/QRCode';

/* Constants */
import * as section from '../../constants/formSections';

/* Dispatch */
import { setFormSection } from '../../actions/donationActions';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';

export default function PaymentMethods() {
  const dispatch = useDispatch();
  const { formSection } = useSelector((state) => ({
    formSection: state.donationReducer.formSection,
  }));

  const goBackToPreviousSection = () => {
    switch (formSection) {
      case section.PAYMENT_METHOD:
        dispatch(setFormSection(section.DONATION_AMOUNT));
        break;
      default:
        dispatch(setFormSection(section.PAYMENT_METHOD));
    }
  };

  /**
   * Returns the corresponding payment method component based on
   * the form section that is declared in the application's state.
   *
   * @return {Element} The payment method component to render.
   */
  const renderPaymentMethod = () => {
    switch (formSection) {
      case section.ONLINE_WALLET:
        return <OnlineWallet />;

      case section.BROWSER_EXTENSTION:
        return <BrowserExtension />;

      case section.HARDWARE_WALLET:
        return <HardwareWallet />;

      case section.QR_CODE:
        return <QRCode />;

      default:
        return <OptionsList />;
    }
  };

  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader onCloseCallback={toggleIsFormOpen}>
            <h1>Select Payment Method</h1>
            <DonationSteps activeStep={section.PAYMENT_METHOD} />
          </DonationFormHeader>

          <DonationFormContent>{renderPaymentMethod()}</DonationFormContent>

          {formSection !== section.QR_CODE && (
            <DonationFormFooter>
              <PaginationButton
                onClickHandler={goBackToPreviousSection}
                type='secondary'>
                Previous
              </PaginationButton>
            </DonationFormFooter>
          )}
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
