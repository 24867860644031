import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* Components */
import Footer from './hoc/footer/Footer';
import Header from './hoc/header/Header';
import PageNotFound from './hoc/page-not-found/PageNotFound';

/* Pages */
import AdminPage from '../pages/admin-page/AdminPage';
import CreateAccount from '../pages/create-account/CreateAccount';
import DonorPortfolio from '../pages/donor-portfolio/DonorPortfolio';
import ProjectPage from '../pages/project-page/ProjectPage';

export default function App() {
  return (
    <>
      <Header />
      <main>
        <Switch>
          <Route exact path='/admin' component={AdminPage} />
          <Route exact path='/portfolio' component={DonorPortfolio} />
          <Route exact path='/signup' component={CreateAccount} />
          <Route exact path='/' component={ProjectPage} />
          <Route component={PageNotFound} />
        </Switch>
      </main>
      <Footer />
    </>
  );
}
