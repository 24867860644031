import React from 'react';

/* CSS */
import './ExploreLinks.scss';

/* Resources */
import images from '../../../../assets/images';

export default function ExploreLinks() {
  return (
    <section className='projectPage__section'>
      <div className='exploreLinks__container'>
        <h2>Explore Other Blockchain Initiatives</h2>
        <div className='exploreLinks__images-wrapper'>
          <figure className='exploreLinks__image'>
            <img src={images.explore_link1} alt='Donate Digital Currency' />
            <a
              className='exploreLinks__image-link'
              href='https://www.heifer.org/give/other/digital-currency.html'
            >
              <div className='exploreLinks__image-text'>
                <h4>Donate Digital Currency</h4>
                <span>
                  Make a donation using digital currency to support Heifer's
                  work creating sustainable change across ecosystems.
                </span>
              </div>
              <button>Learn More</button>
            </a>
          </figure>
          <figure className='exploreLinks__image'>
            <img src={images.explore_link2} alt='Blockchain Initiatives' />
            <a
              className='exploreLinks__image-link'
              href='https://www.heifer.org/campaign/2019/blockchain-initiatives.html'
            >
              <div className='exploreLinks__image-text'>
                <h4>Blockchain Initiatives</h4>
                <span>
                  We believe blockchain technology will forever change the way
                  non-profit organizations create value for beneficiaries and
                  project stakeholders.
                </span>
              </div>
              <button>Learn More</button>
            </a>
          </figure>
        </div>
      </div>
    </section>
  );
}
