import { actionTypes as types } from '../actions/donationActions';
import { DONOR_INFORMATION } from '../constants/formSections';

const initialState = {
  coinbaseToken: '',
  donationAmount: 0,
  donationAmountUSD: 0,
  donationCurrency: '',
  donorDisplayName: '',
  donorEmailAddress: '',
  donorFullLegalName: '',
  donorWalletAddress: '',
  formSection: DONOR_INFORMATION,
  isDonationUnderReview: false,
  isDonorAnonymous: false,
  isDonorWalletAddressVerified: false,
  onlineWalletPlatform: '',
  projectMemoKey: null,
  projectMemoQRCode: null,
  projectQRCode: null,
  projectWalletAddress: null,
  transactionId: '',
  willDonorReceiveTaxReceipt: false,
  willDonorReceiveUpdates: false,
  willDonationRecur: false,
};

export default function donationReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_DONATION:
      return initialState;

    case types.SET_DONATION_AMOUNT:
      return { ...state, donationAmount: action.payload };

    case types.SET_DONATION_AMOUNT_USD:
      return { ...state, donationAmountUSD: action.payload };

    case types.SET_DONATION_CURRENCY:
      return { ...state, donationCurrency: action.payload };

    case types.SET_DONOR_DISPLAY_NAME:
      return { ...state, donorDisplayName: action.payload };

    case types.SET_DONOR_EMAIL_ADDRESS:
      return { ...state, donorEmailAddress: action.payload };

    case types.SET_DONOR_FULL_LEGAL_NAME:
      return { ...state, donorFullLegalName: action.payload };

    case types.SET_DONOR_WALLET_ADDRESS:
      return { ...state, donorWalletAddress: action.payload };

    case types.SET_FORM_SECTION:
      return { ...state, formSection: action.payload };

    case types.SET_ONLINE_WALLET_PLATFORM:
      return { ...state, onlineWalletPlatform: action.payload };

    case types.SET_COINBASE_TOKEN:
      return { ...state, coinbaseToken: action.payload };

    case types.SET_PROJECT_MEMO_KEY:
      return { ...state, projectMemoKey: action.payload };

    case types.SET_PROJECT_MEMO_QR_CODE:
      return { ...state, projectMemoQRCode: action.payload };

    case types.SET_PROJECT_QR_CODE:
      return { ...state, projectQRCode: action.payload };

    case types.SET_PROJECT_WALLET_ADDRESS:
      return { ...state, projectWalletAddress: action.payload };

    case types.SET_TRANSACTION_ID:
      return { ...state, transactionId: action.payload };

    case types.TOGGLE_IS_DONATION_UNDER_REVIEW:
      return { ...state, isDonationUnderReview: !state.isDonationUnderReview };

    case types.TOGGLE_IS_DONOR_ANONYMOUS:
      return { ...state, isDonorAnonymous: !state.isDonorAnonymous };

    case types.TOGGLE_IS_DONOR_WALLET_ADDRESS_VERIFIED:
      return {
        ...state,
        isDonorWalletAddressVerified: !state.isDonorWalletAddressVerified,
      };

    case types.TOGGLE_WILL_DONOR_RECEIVE_TAX_RECEIPT:
      return {
        ...state,
        willDonorReceiveTaxReceipt: !state.willDonorReceiveTaxReceipt,
      };

    case types.TOGGLE_WILL_DONATION_RECUR:
      return {
        ...state,
        willDonationRecur: !state.willDonationRecur,
      };

    case types.TOGGLE_WILL_DONOR_RECEIVE_UPDATES:
      return {
        ...state,
        willDonorReceiveUpdates: !state.willDonorReceiveUpdates,
      };

    default:
      return state;
  }
}
