const images = {
  bar_graph: require('./img/bar_graph.PNG'),
  binance: require('./img/binance.png'),
  bitcoinqr: require('./img/bitcoinqr.jpg'),
  celoqr: require('./img/celoqr.jpg'),
  coinbase: require('./img/coinbase.jpg'),
  cacao: require('./img/cacao.jpeg'),
  doge: require('./img/dogecoin.svg'),
  dogeqr: require('./img/dogeqr.png'),
  diem: require('./img/diem.jpg'),
  eosqr: require('./img/eosqr.jpg'),
  eosmemo: require('./img/eosmemo.jpg'),
  ethereumqr: require('./img/ethereumqr.jpg'),
  explore_link1: require('./img/explore_link1.jpg'),
  explore_link2: require('./img/explore_link2.jpg'),
  gemini: require('./img/gemini.png'),
  geminiqr: require('./img/geminiqr.jpg'),
  keepkey: require('./img/keepkey.png'),
  kraken: require('./img/kraken.png'),
  ledger: require('./img/ledger.png'),
  litecoinqr: require('./img/litecoinqr.jpg'),
  linkqr: require('./img/linkqr.png'),
  logo: require('./img/logo-navy.svg'),
  main: require('./img/main.jpg'),
  metamask: require('./img/metamask.jpg'),
  paypal: require('./img/paypal.png'),
  pie_chart: require('./img/pie_chart.PNG'),
  stellarqr: require('./img/stellarqr.jpg'),
  stellarmemo: require('./img/stellarmemo.jpg'),
  testqr: require('./img/testqr.png'),
  trezor: require('./img/trezor.png'),
  whatsapp: require('./img/whatsapp2.jpg')
};

export default images;
