import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

/* Components */
import { PaginationButton } from '../../components/buttons';
import {
  DonationFormContent,
  DonationFormFooter,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { PaginationButtonGroup } from '../../components/groups';

/* Constants */
import * as section from '../../constants/formSections';

/* Dispatch */
import { setFormSection } from '../../actions/donationActions';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';

/* Styles */
import './DonationConfirmation.scss';

import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";


export default function DonationConfirmation() {
  const dispatch = useDispatch();
  const history = useHistory();

  const closeDonationForm = (closeForm) => {
    closeForm();
    history.push('/');
  };
  let title = "I just donated crypto to smallholder cacao farmers in Honduras through @Heifer’s virtual currency donation tracker. Click here to transparently give to the Chocolate for All project 🐄🍫💸#bitcoin #ethereum #stellar #dogecoin #Litecoin #EOS #chainlink";
  let shareUrl = "donationtracker.heifer.org";
  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader
            onCloseCallback={() => closeDonationForm(toggleIsFormOpen)}>
            <h1 className='display-4 text-center'>Congratulations!</h1>
          </DonationFormHeader>

          <DonationFormContent>
            <div className='text-center'>
              <span>
                You made a difference towards sustainably ending hunger and
                poverty!
              </span>
            </div>
            <div className='social-row'>
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                style={{padding: '10px'}}
              >
                <FacebookIcon size={64} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                style={{padding: '10px'}}
              >
                <TwitterIcon size={64} round />
              </TwitterShareButton>
              <TelegramShareButton
                url={shareUrl}
                title={title}
                style={{padding: '10px'}}
              >
            <TelegramIcon size={64} round />
          </TelegramShareButton>
            </div>
          </DonationFormContent>

          <DonationFormFooter>
            <PaginationButtonGroup>
              <PaginationButton
                onClickHandler={() =>
                  dispatch(setFormSection(section.PROVIDE_FEEDBACK))
                }
                type='primary'>
                Leave us feedback
              </PaginationButton>
              <PaginationButton
                onClickHandler={() => closeDonationForm(toggleIsFormOpen)}
                type='secondary'>
                Return to Project
              </PaginationButton>
            </PaginationButtonGroup>
          </DonationFormFooter>
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
