import React from 'react';

/* Components */
import { DonationFormTooltip } from '../tooltips';

/* CSS */
import './Checkboxes.scss';

/* Libraries */
import clsx from 'clsx';
import PropTypes from 'prop-types';

DonationFormCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.shape({
    position: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export function DonationFormCheckbox(props) {
  const { checked, disabled, label, onChange, tooltip } = props;

  return (
    <div className='donationFormCheckbox__wrapper'>
      <label className={clsx('donationFormCheckbox', disabled && 'disabled')}>
        <input
          type='checkbox'
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span>{label}</span>
        <span className='checkmark'></span>
      </label>
      {!!tooltip && <DonationFormTooltip tooltip={tooltip} />}
    </div>
  );
}
