import React, { useEffect, useState } from 'react';

/* Containers */
import DonationTable from './containers/donation-table/DonationTable';
import ExploreLinks from './containers/explore-links/ExploreLinks';
import MilestoneList from './containers/milestone-list/MilestoneList';
import ProjectDetails from './containers/project-details/ProjectDetails';
import ProjectHeader from './containers/project-header/ProjectHeader';

/* Pages */
import DonationModal from '../donation-modal/DonationModal';

/* Service */
import { DonationFormContext } from '../donation-modal/services/DonationFormContext';

export default function ProjectPage() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hasRedirected, setHasRedirected] = useState(false);
  const params = new URLSearchParams(window.location.search);

  const formContext = {
    fullScreen: window.innerWidth <= 400,
    isFormOpen,
    toggleIsFormOpen: () => {
      setIsFormOpen(!isFormOpen);
    },
  };

  useEffect(() => {
    // Handle displaying the donation form after a redirect from Coinbase
    if (!hasRedirected) {
      !!params.get('data') && setIsFormOpen(true);
      setHasRedirected(true);
    }
  }, [hasRedirected, params]);

  // Todo: remove this when all form sections consume from context
  const openDonationForm = () => {
    setIsFormOpen(true);
  };

  // Todo: rename all DonationModal to DonationForm
  return (
    <>
      <DonationFormContext.Provider value={formContext}>
        <DonationModal />
      </DonationFormContext.Provider>
      <ProjectHeader openDonationModal={openDonationForm} />
      <ProjectDetails openDonationModal={openDonationForm} />
      <MilestoneList />
      <DonationTable openDonationModal={openDonationForm} />
      <ExploreLinks />
    </>
  );
}
