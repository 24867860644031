import React from 'react';

/* CSS */
import './Groups.scss';

/* Libraries */
import PropTypes from 'prop-types';

FormGroup.propTypes = {
  children: PropTypes.node,
  showRequirementWarning: PropTypes.bool,
};

export function FormGroup(props) {
  const { showRequirementWarning } = props;

  return (
    <div className='formGroup'>
      <div className='requirement-warning'>
        {!!showRequirementWarning && (
          <span>Fields marked with an asterisk (*) are required.</span>
        )}
      </div>
      {props.children}
    </div>
  );
}

PaginationButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export function PaginationButtonGroup(props) {
  return <div className='paginationButtonGroup'>{props.children}</div>;
}
