import React from 'react';

/* Components */
import App from './app/App';
import rootReducer from './store/reducer';

/* Global CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './styles.scss';

/* Libraries */
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { render } from 'react-dom';

const store = createStore(rootReducer);

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
