/**
 * Catch a redirect from coinbase, repopulate the donation form,
 * and navigate to the review donation section.
 * @param {*} dispatch The dispatcher for redirect state changes.
 */
export function catchCoinbaseRedirect(dispatch) {}

/**
 * Determines if a string is empty.
 * @param {string} value The string to be empty checked.
 */
export function isEmpty(value) {
  return value.length === 0;
}

/**
 * Validates a string to be an email address.
 * @param {string} email The string to be tested against an email regex pattern.
 */
export function isValidEmail(email) {
  const pattern = /^([a-zA-Z0-9.]+)([@]{1})([a-zA-Z]+[\.]{1}[a-zA-Z]+)+$/;
  return email.match(pattern);
}
