const RESET_DONATION = 'RESET_DONATION';
const SET_COINBASE_TOKEN = 'SET_COINBASE_TOKEN';
const SET_DONATION_AMOUNT = 'SET_DONATION_AMOUNT';
const SET_DONATION_AMOUNT_USD = 'SET_DONATION_AMOUNT_USD';
const SET_DONATION_CURRENCY = 'SET_DONATION_CURRENCY';
const SET_DONOR_DISPLAY_NAME = 'SET_DONOR_DISPLAY_NAME';
const SET_DONOR_EMAIL_ADDRESS = 'SET_DONOR_EMAIL_ADDRESS';
const SET_DONOR_FULL_LEGAL_NAME = '  SET_DONOR_FULL_LEGAL_NAME';
const SET_DONOR_WALLET_ADDRESS = 'SET_DONOR_WALLET_ADDRESS';
const SET_FORM_SECTION = 'SET_FORM_SECTION';
const SET_ONLINE_WALLET_PLATFORM = 'SET_ONLINE_WALLET_PLATFORM';
const SET_PROJECT_MEMO_KEY = 'SET_PROJECT_MEMO_KEY';
const SET_PROJECT_MEMO_QR_CODE = 'SET_PROJECT_MEMO_QR_CODE';
const SET_PROJECT_QR_CODE = 'SET_PROJECT_QR_CODE';
const SET_PROJECT_WALLET_ADDRESS = 'SET_PROJECT_WALLET_ADDRESS';
const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
const TOGGLE_IS_DONATION_UNDER_REVIEW = 'TOGGLE_IS_DONATION_UNDER_REVIEW';
const TOGGLE_IS_DONOR_ANONYMOUS = 'TOGGLE_IS_DONOR_ANONYMOUS';
const TOGGLE_IS_DONOR_WALLET_ADDRESS_VERIFIED =
  'TOGGLE_IS_DONOR_WALLET_ADDRESS_VERIFIED';
const TOGGLE_WILL_DONOR_RECEIVE_TAX_RECEIPT =
  'TOGGLE_WILL_DONOR_RECEIVE_TAX_RECEIPT';
const TOGGLE_WILL_DONOR_RECEIVE_UPDATES = 'TOGGLE_WILL_DONOR_RECEIVE_UPDATES';
const TOGGLE_WILL_DONATION_RECUR = 'TOGGLE_WILL_DONATION_RECUR'

export const actionTypes = {
  RESET_DONATION,
  SET_COINBASE_TOKEN,
  SET_DONATION_AMOUNT,
  SET_DONATION_AMOUNT_USD,
  SET_DONATION_CURRENCY,
  SET_DONOR_DISPLAY_NAME,
  SET_DONOR_EMAIL_ADDRESS,
  SET_DONOR_FULL_LEGAL_NAME,
  SET_DONOR_WALLET_ADDRESS,
  SET_FORM_SECTION,
  SET_ONLINE_WALLET_PLATFORM,
  SET_PROJECT_MEMO_KEY,
  SET_PROJECT_MEMO_QR_CODE,
  SET_PROJECT_QR_CODE,
  SET_PROJECT_WALLET_ADDRESS,
  SET_TRANSACTION_ID,
  TOGGLE_IS_DONATION_UNDER_REVIEW,
  TOGGLE_IS_DONOR_ANONYMOUS,
  TOGGLE_IS_DONOR_WALLET_ADDRESS_VERIFIED,
  TOGGLE_WILL_DONOR_RECEIVE_TAX_RECEIPT,
  TOGGLE_WILL_DONOR_RECEIVE_UPDATES,
  TOGGLE_WILL_DONATION_RECUR
};

export function resetDonation() {
  return { type: actionTypes.RESET_DONATION };
}

export function setCoinbaseToken(payload) {
  return { type: actionTypes.SET_COINBASE_TOKEN, payload };
}

export function setDonationAmount(payload) {
  return { type: actionTypes.SET_DONATION_AMOUNT, payload };
}

export function setDonationAmountUSD(payload) {
  return { type: actionTypes.SET_DONATION_AMOUNT_USD, payload };
}

export function setDonationCurrency(payload) {
  return { type: actionTypes.SET_DONATION_CURRENCY, payload };
}

export function setDonorDisplayName(payload) {
  return { type: actionTypes.SET_DONOR_DISPLAY_NAME, payload };
}

export function setDonorEmailAddress(payload) {
  return { type: actionTypes.SET_DONOR_EMAIL_ADDRESS, payload };
}

export function setDonorFullLegalName(payload) {
  return { type: actionTypes.SET_DONOR_FULL_LEGAL_NAME, payload };
}

export function setDonorWalletAddress(payload) {
  return { type: actionTypes.SET_DONOR_WALLET_ADDRESS, payload };
}

export function setFormSection(payload) {
  return { type: actionTypes.SET_FORM_SECTION, payload };
}

export function setOnlineWalletPlatform(payload) {
  return { type: actionTypes.SET_ONLINE_WALLET_PLATFORM, payload };
}

export function setProjectMemoKey(payload) {
  return { type: actionTypes.SET_PROJECT_MEMO_KEY, payload };
}

export function setProjectMemoQRCode(payload) {
  return { type: actionTypes.SET_PROJECT_MEMO_QR_CODE, payload };
}

export function setProjectQRCode(payload) {
  return { type: actionTypes.SET_PROJECT_QR_CODE, payload };
}

export function setProjectWalletAddress(payload) {
  return { type: actionTypes.SET_PROJECT_WALLET_ADDRESS, payload };
}

export function setTransactionId(payload) {
  return { type: actionTypes.SET_TRANSACTION_ID, payload };
}

export function toggleIsDonationUnderReview() {
  return { type: actionTypes.TOGGLE_IS_DONATION_UNDER_REVIEW };
}

export function toggleIsDonorAnonymous() {
  return { type: actionTypes.TOGGLE_IS_DONOR_ANONYMOUS };
}

export function toggleWillDonationRecur() {
  return { type: actionTypes.TOGGLE_WILL_DONATION_RECUR };
}

export function toggleIsDonorWalletAddressVerified() {
  return { type: actionTypes.TOGGLE_IS_DONOR_WALLET_ADDRESS_VERIFIED };
}

export function toggleWillDonorReceiveTaxReceipt() {
  return { type: actionTypes.TOGGLE_WILL_DONOR_RECEIVE_TAX_RECEIPT };
}

export function toggleWillDonorReceiveUpdates() {
  return { type: actionTypes.TOGGLE_WILL_DONOR_RECEIVE_UPDATES };
}
