import React from 'react';

/* Icons */
import CloseIcon from '@material-ui/icons/Close';

/* Libraries */
import PropTypes from 'prop-types';

/* Styles */
import './index.scss';

DonationFormSection.propTypes = { children: PropTypes.node };

DonationFormHeader.propTypes = {
  children: PropTypes.node,
  onCloseCallback: PropTypes.func.isRequired,
};

DonationFormContent.propTypes = { children: PropTypes.node };

DonationFormFooter.propTypes = { children: PropTypes.node };

export function DonationFormSection(props) {
  return <section className='donationModal__section'>{props.children}</section>;
}

export function DonationFormHeader(props) {
  const { onCloseCallback } = props;

  return (
    <header className='donation-form-header'>
      <div className='flex-row end header-controls'>
        <button className='icon-button' onClick={onCloseCallback}>
          <CloseIcon />
        </button>
      </div>
      {props.children}
    </header>
  );
}

export function DonationFormContent(props) {
  return <div className='donation-form-content'>{props.children}</div>;
}

export function DonationFormFooter(props) {
  return <div className='donation-form-footer'>{props.children}</div>;
}
