import React from 'react';

/* CSS */
import './Header.scss';

export default function Header() {
  return (
    <header className='simpleHeader'>
      <nav>
        <div className='simpleHeader__wrapper'>
          <a className='simpleHeader__logo-link' href='/'>
            <img
              className='mainHeader__logo'
              alt='Heifer International'
              src='https://media.heifer.org/logo-navy.svg'
            />
          </a>
          <div className='simpleHeader__content'>
            <h5 className='simpleHeader__content-header'>Need Help?</h5>
            <a href='https://www.heifer.org/campaign/tel:8559486437'>
              855.9HUNGER (855.948.6437)
            </a>
            <a href='/'>Live Chat</a>
          </div>
        </div>
      </nav>
    </header>
  );
}
