import React from 'react';

/* CSS */
import './ProjectHeader.scss';

/* Effects */
import { useProjectHeader } from '../../effects';

/* Libraries */
import PropTypes from 'prop-types';

ProjectHeader.propTypes = {
  openDonationModal: PropTypes.func.isRequired,
};

export default function ProjectHeader(props) {
  const { openDonationModal } = props;
  const { header, stats } = useProjectHeader();

  return (
    <>
      <section className='projectPage__section'>
        <div className='projectHeader__container'>
          <div className='projectHeader__content'>
            <h1 className='project-title'>{header.title}</h1>
            <h1 className='project-location'>{header.location}</h1>
            <h2 className='project-subtitle'>{header.subtitle}</h2>
            <p className='project-description'>{header.description}</p>
            <button
              className='primary-btn'
              type='button'
              onClick={openDonationModal}
            >
              <span className='button-text'>Donate Digital Currency</span>
            </button>
          </div>
        </div>
      </section>

      <div className='projectHeader__definition'>
        <div className='projectDefinition__container'>
          <figure className='map'>
            <img
              data-original='https://media.heifer.org/About_Us/Where_We_Work_Maps/Honduras_2020_Map_5.svg?or=0&q=60&crop=faces%2Centropy%2Ccenter&auto=format&fm=jpeg&shad=50&vib=20&duotone-alpha=40&duotone=000000%2C2CFFD4C6&fit=crop'
              alt='Detailed map highlighting the geographic locations of past and active projects in Rwanda, Tanzania, Uganda, and Kenya.'
              data-src='https://media.heifer.org/About_Us/Where_We_Work_Maps/Honduras_2020_Map_5.svg?or=0&q=60&crop=faces%2Centropy%2Ccenter&auto=format&fm=jpeg&shad=50&vib=20&duotone-alpha=40&duotone=000000%2C2CFFD4C6&fit=crop&amp;w=500'
              src='https://media.heifer.org/About_Us/Where_We_Work_Maps/Honduras_2020_Map_5.svg?or=0&q=60&crop=faces%2Centropy%2Ccenter&auto=format&fm=jpeg&shad=50&vib=20&duotone-alpha=40&duotone=000000%2C2CFFD4C6&fit=crop&amp;w=500'
            />
          </figure>
          <dl className='stats'>
            <div id='location'>
              <dt>Location</dt>
              <dd>{stats.location}</dd>
            </div>
            <div id='products'>
              <dt>Products</dt>
              <dd>{stats.products}</dd>
            </div>
            <div id='active'>
              <dt>Active Since</dt>
              <dd>{stats.activeSince}</dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
