import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaymentMethodCard } from '../../../components/cards';
import { UserMessage } from '../../../components/messages';
import { DonationFormTooltip } from '../../../components/tooltips';

/* Constants */
import * as section from '../../../constants/formSections';
import { userMessages as messages } from '../../../constants/userMessages';

/* Dispatch */
import {
  setFormSection,
  setTransactionId,
  setOnlineWalletPlatform,
} from '../../../actions/donationActions';

/* Resources */
import images from '../../../../../assets/images';

export default function BrowserExtension() {
  const { donationAmount, projectWalletAddress } = useSelector(
    (state) => state.donationReducer
  );
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const getMetamaskIcon = () => {
    return (
      <img src={images.metamask} height='100%' width='100%' alt='Metamask' />
    );
  };

  const getMetamaskTooltip = () => {
    return {
      position: 'bottom',
      text: messages.METAMASK_TOOLTIP,
    };
  };

  const metamaskTransact = async () => {
    const mm = window.ethereum;
    const web3 = require('web3');
    console.log('web3');
    if (mm && mm.isMetaMask) {
      try {
        const accounts = await mm.enable();
        const send = accounts[0];
        const rec = projectWalletAddress;
        const amount = web3.utils.toHex(
          web3.utils.toWei(donationAmount.toString(), 'ether')
        );
        const params = [
          {
            from: send,
            to: rec,
            value: amount,
          },
        ];
        mm.send(
          {
            method: 'eth_sendTransaction',
            params: params,
            from: send,
          },
          (err, response) => {
            if (err) {
              setErrorMessage(err.message);
            } else {
              setErrorMessage('');
              dispatch(setTransactionId(response.result));
              if (errorMessage === '') {
                dispatch(setOnlineWalletPlatform('metamask'));
                dispatch(setFormSection(section.REVIEW_DONATION));
              }
            }
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <form>
      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
          <PaymentMethodCard
            body='Connect to Metamask'
            header='Metamask'
            icon={getMetamaskIcon()}
            onClick={metamaskTransact}
          />
        </div>
        <div className='col-1 ml-auto flex-column center'>
          <DonationFormTooltip tooltip={getMetamaskTooltip()} />
        </div>
      </div>

      {!!errorMessage && (
        <div className='row no-gutters'>
          <div className='col-12 col-mb-10 offset-mb-1'>
            <UserMessage message={errorMessage} type='error' />
          </div>
        </div>
      )}
    </form>
  );
}
