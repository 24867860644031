import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import Dialog from '@material-ui/core/Dialog';
import DonorInformation from './form-sections/1-donor-information/DonorInformation';
import Customizations from './form-sections/8-customizations/Customizations';
import SelectCurrency from './form-sections/2-select-currency/SelectCurrency';
import DonationAmount from './form-sections/3-donation-amount/DonationAmount';
import PaymentMethods from './form-sections/4-payment-method/PaymentMethods';
import ReviewDonation from './form-sections/5-review-donation/ReviewDonation';
import DonationConfirmation from './form-sections/6-donation-confirmation/DonationConfirmation';
import ProvideFeedback from './form-sections/7-provide-feedback/ProvideFeedback';

/* Constants */
import * as section from './constants/formSections';

/* Models */
import { acceptedDonationCurrencies } from '../../models/currencies';

/* Services */
import {
  resetDonation,
  setDonationAmount,
  setDonationAmountUSD,
  setDonationCurrency,
  setDonorDisplayName,
  setDonorFullLegalName,
  setDonorEmailAddress,
  setFormSection,
  setOnlineWalletPlatform,
  setProjectQRCode,
  setProjectWalletAddress,
  toggleIsDonorAnonymous,
  toggleWillDonorReceiveTaxReceipt,
  toggleWillDonorReceiveUpdates,
} from './actions/donationActions';
import { DonationFormContext } from './services/DonationFormContext';

/* Styles */
import './DonationModal.scss';

export default function DonationModal() {
  const { formSection, onlineWalletPlatform } = useSelector(
    (state) => state.donationReducer
  );
  const dispatch = useDispatch();

  /**
   * Catch a redirect from coinbase and navigate to the review donation section.
   * Todo: Move this logic outside the component (maybe a helper service)
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!!params.get('data') && onlineWalletPlatform !== 'qr') {
      const Cryptr = require('cryptr');
      const cryptr = new Cryptr('HeiferLabsIstheBest');
      const encrypted = params.get('data');
      const decryptedString = cryptr.decrypt(encrypted);
      const cookie = decryptedString.split('-');
      cookie[0] === 'true' && dispatch(toggleIsDonorAnonymous());
      dispatch(setDonorDisplayName(cookie[1].replace('_', ' ')));
      dispatch(setDonorEmailAddress(cookie[2].replace('%40', '@')));
      cookie[3] === 'true' && dispatch(toggleWillDonorReceiveUpdates());
      cookie[4] === 'true' && dispatch(toggleWillDonorReceiveTaxReceipt());
      const { qrCode, walletAddress } = acceptedDonationCurrencies.find(
        (currency) => currency.network === cookie[5]
      );
      dispatch(setDonationCurrency(cookie[5]));
      dispatch(setProjectQRCode(qrCode));
      dispatch(setProjectWalletAddress(walletAddress));
      dispatch(setDonationAmount(Number(cookie[6])));
      dispatch(setDonationAmountUSD(Number(cookie[7])));
      dispatch(setDonorFullLegalName(cookie[8]));
      dispatch(setOnlineWalletPlatform('qr'));
      dispatch(setFormSection(section.QR_CODE));
    }
  });

  const executeExitCallback = () => {
    switch (formSection) {
      case section.DONATION_CONFIRMATION:
      case section.PROVIDE_FEEDBACK:
        dispatch(resetDonation());
        break;
      default:
        dispatch(setFormSection(section.DONOR_INFORMATION));
    }
  };

  /**
   * Render the correct form section based upon the state.
   * @return {Element} The form section component to render.
   */
  const renderFormSection = () => {
    switch (formSection) {
      case section.DONOR_INFORMATION:
        return <DonorInformation />;

      case section.CUSTOMIZATIONS:
        return <Customizations/>

      case section.SELECT_CURRENCY:
        return <SelectCurrency />;

      case section.DONATION_AMOUNT:
        return <DonationAmount />;

      case section.REVIEW_DONATION:
        return <ReviewDonation />;

      case section.DONATION_CONFIRMATION:
        return <DonationConfirmation />;

      case section.PROVIDE_FEEDBACK:
        return <ProvideFeedback />;

      default:
        return <PaymentMethods />;
    }
  };

  // Todo: add fullscreen modal to controls / default to it on mobile
  return (
    <DonationFormContext.Consumer>
      {({ fullScreen, isFormOpen, toggleIsFormOpen }) => (
        <Dialog
          fullScreen={fullScreen}
          open={isFormOpen}
          onClose={toggleIsFormOpen}
          onExited={executeExitCallback}>
          <div className='container'>{renderFormSection()}</div>
        </Dialog>
      )}
    </DonationFormContext.Consumer>
  );
}
