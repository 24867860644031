import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaginationButton } from '../../components/buttons';
import { DonationFormCheckbox } from '../../components/checkboxes';
import {
  DonationFormContent,
  DonationFormFooter,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { DonationFormInput } from '../../components/inputs';
import { UserMessage } from '../../components/messages';
import { DonationFormTooltip } from '../../components/tooltips';

/* Constants */
import * as section from '../../constants/formSections';
import { userMessages as messages } from '../../constants/userMessages';

/* Dispatch */
import {
  setDonorDisplayName,
  setDonorEmailAddress,
  setDonorFullLegalName,
  setFormSection,
  toggleIsDonationUnderReview,
  toggleIsDonorAnonymous,
  toggleWillDonorReceiveTaxReceipt,
  toggleWillDonorReceiveUpdates,
} from '../../actions/donationActions';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';
import * as Utils from '../../services/DonationFormUtils';

export default function DonorInformation() {
  const {
    donorDisplayName,
    donorEmailAddress,
    donorFullLegalName,
    isDonationUnderReview,
    isDonorAnonymous,
    willDonorReceiveTaxReceipt,
    willDonorReceiveUpdates,
  } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const continueToNextSection = () => {
    if (isFormSectionValid()) {
      if (isDonationUnderReview) {
        dispatch(setFormSection(section.REVIEW_DONATION));
        dispatch(toggleIsDonationUnderReview());
      } else {
        isDonorAnonymous ?
        dispatch(setFormSection(section.SELECT_CURRENCY)) : 
        dispatch(setFormSection(section.CUSTOMIZATIONS));
      }
    }
  };

  const getAnonymityCheckboxTooltip = () => {
    return {
      position: 'bottom',
      text: messages.ANONYMOUS_CHECKBOX_TOOLTIP,
    };
  };

  const getDonorDisplayNameTooltip = () => {
    return {
      position: 'bottom',
      text: isDonorAnonymous
        ? messages.DISABLED_DISPLAY_NAME_TOOLTIP
        : messages.ENABLED_DISPLAY_NAME_TOOLTIP,
    };
  };

  const getDonorFullLegalNameTooltip = () => {
    return {
      position: 'top',
      text: messages.DONOR_LEGAL_NAME_TOOLTIP,
    };
  };

  const getWillDonorReceiveTaxReceiptTooltip = () => {
    return {
      position: 'top',
      text: isDonorAnonymous
        ? messages.DISABLED_TAX_RECEIPT_TOOLTIP
        : messages.ENABLED_TAX_RECEIPT_TOOLTIP,
    };
  };

  const getWillDonorReceiveUpdatesTooltip = () => {
    return {
      position: 'top',
      text: isDonorAnonymous
        ? messages.DISABLED_UPDATES_TOOLTIP
        : messages.ENABLED_UPDATES_TOOLTIP,
    };
  };

  const isFormSectionValid = () => {
    let errorMessage = '';

    if (!isDonorAnonymous) {
      if (Utils.isEmpty(donorDisplayName)) {
        errorMessage = messages.EMPTY_DISPLAY_NAME;
      } else if (Utils.isEmpty(donorEmailAddress)) {
        errorMessage = messages.EMPTY_EMAIL_ADDRESS;
      } else if (!Utils.isValidEmail(donorEmailAddress)) {
        errorMessage = messages.INVALID_EMAIL_ADDRESS;
      } else if (
        willDonorReceiveTaxReceipt &&
        Utils.isEmpty(donorFullLegalName)
      ) {
        errorMessage = messages.EMPTY_LEGAL_NAME;
      }
    }

    setErrorMessage(errorMessage);
    return errorMessage.length === 0;
  };

  // TODO: Move logic heavy functions to a helper/utils class
  const updateIsDonorAnonymous = (event) => {
    if (event.target.checked) {
      dispatch(setDonorDisplayName('Anonymous Donor'));
      dispatch(setDonorEmailAddress('N/A'));
      dispatch(setDonorFullLegalName('N/A'));
      willDonorReceiveTaxReceipt &&
        dispatch(toggleWillDonorReceiveTaxReceipt());
      willDonorReceiveUpdates && dispatch(toggleWillDonorReceiveUpdates());
    } else {
      dispatch(setDonorDisplayName(''));
      dispatch(setDonorEmailAddress(''));
      dispatch(setDonorFullLegalName(''));
    }

    dispatch(toggleIsDonorAnonymous());
    setErrorMessage('');
  };

  const updateDonorDisplayName = (event) => {
    dispatch(setDonorDisplayName(event.target.value));
    setErrorMessage('');
  };

  const updateDonorEmailAddress = (event) => {
    dispatch(setDonorEmailAddress(event.target.value));
    setErrorMessage('');
  };

  const updateDonorFullLegalName = (event) => {
    dispatch(setDonorFullLegalName(event.target.value));
    setErrorMessage('');
  };

  const updateWillDonorReceiveTaxReceipt = () => {
    dispatch(toggleWillDonorReceiveTaxReceipt());
  };

  const updateWillDonorReceiveUpdates = () => {
    dispatch(toggleWillDonorReceiveUpdates());
  };

  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader onCloseCallback={toggleIsFormOpen}>
            <h1>Make a Donation</h1>
            <p>
              Providing mimimal contact information allows donors to receive
              project impact updates, as well as tax receipts.
            </p>
            <div className='flex-row center vertical-center requirement-warning'>
              <span>Fields marked with an asterisk (*) are required.</span>
            </div>
          </DonationFormHeader>

          <DonationFormContent>
            <form autoComplete='off' noValidate>
              <div className='row no-gutters' style={{ paddingBottom: '1rem' }}>
                <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
                  <DonationFormCheckbox
                    checked={isDonorAnonymous}
                    label='I want my donation to be anonymous'
                    onChange={updateIsDonorAnonymous}
                  />
                </div>
                <div className='col-1 ml-auto flex-column center'>
                  <DonationFormTooltip
                    tooltip={getAnonymityCheckboxTooltip()}
                  />
                </div>
              </div>

              <div className='row no-gutters'>
                <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
                  <DonationFormInput
                    id='display-name'
                    label='Display Name'
                    onChange={updateDonorDisplayName}
                    readOnly={isDonorAnonymous}
                    required={!isDonorAnonymous}
                    value={donorDisplayName}
                  />
                </div>
                <div className='col-1 ml-auto flex-column center'>
                  <DonationFormTooltip tooltip={getDonorDisplayNameTooltip()} />
                </div>
              </div>

              <div className='row no-gutters'>
                <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
                  <DonationFormInput
                    id='email'
                    label='Email'
                    onChange={updateDonorEmailAddress}
                    readOnly={isDonorAnonymous}
                    required={!isDonorAnonymous}
                    value={donorEmailAddress}
                  />
                </div>
              </div>


              {!!errorMessage && (
                <div className='row no-gutters'>
                  <div className='col-12 col-mb-10 offset-mb-1'>
                    <UserMessage message={errorMessage} type='error' />
                  </div>
                </div>
              )}
            </form>
          </DonationFormContent>

          <DonationFormFooter>
            <PaginationButton
              onClickHandler={continueToNextSection}
              type='primary'>
              Continue
            </PaginationButton>
          </DonationFormFooter>
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
