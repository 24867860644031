import React, { useState, useEffect } from 'react';

/* CSS */
import './DonorPortfolio.css';

/* Components */
import images from '../../assets/images';

/* Libraries */
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import firebase from '../../firebase';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export default function DonorPortfolio() {
  const [donations, setDonations] = useState([]);
  const history = useHistory();

  const donationTableColumns = [
    {
      width: '20%',
      label: 'Donor',
    },
    {
      width: '20%',
      label: 'Amount (USD)',
    },
    {
      width: '20%',
      label: 'Amount (Crypto)',
    },
    {
      width: '20%',
      label: 'Date / Time',
    },
    {
      width: '20%',
      label: 'Transaction ID',
    },
  ];

  /* Pull all donations that belong to the user */
  useEffect(() => {
    const projectId = 1;
    firebase
      .database()
      .ref(`projects/${projectId}/donations`)
      .on('value', (snapshot) => {
        let items = snapshot.val();
        let newState = [];
        for (let item in items) {
          if (items[item].displayName === 'Anonymous Donor') {
            newState.push({
              id: item,
              displayName: items[item].displayName,
              donationAmount: '$' + items[item].donationAmountUSD,
              donationCurrency:
                items[item].donationAmount + ' ' + items[item].digitalCurrency,
              donationDate: `${moment(items[item].donationDate).format(
                'MM/DD/YYYY'
              )} ${items[item].donationTime}`,
            });
          }
        }
        setDonations(newState.reverse());
      });
  }, []);

  return (
    <section>
      <header className='portfolio-header'>
        <h1 className='display-3 text-center'>My Donation Portfolio</h1>
      </header>

      {/* NAVIGATION */}
      <div className='row p-2'>
        <button
          className='primary-btn back-button'
          type='button'
          onClick={() => history.goBack()}
        >
          <span className='m-0'>Back to Project</span>
        </button>
      </div>

      {/* BAR GRAPH */}
      <div className='chart-container'>
        <h1 className='display-4 text-center'>Donations By Month</h1>
        <div className='col-8 offset-2'>
          <figure className='text-center'>
            <img
              className='bar-graph'
              src={images.bar_graph}
              alt='Bar graph displaying donations by month'
            ></img>
          </figure>
        </div>
      </div>

      {/* PIE CHART */}
      <div className='chart-container'>
        <h1 className='display-4 text-center'>Donations By Project</h1>
        <div className='col-8 offset-2'>
          <figure className='text-center'>
            <img
              className='bar-graph'
              src={images.pie_chart}
              alt='Pie chart displaying'
            ></img>
          </figure>
        </div>
      </div>

      {/* DONATIONS TABLE */}
      <div className='col-6 offset-3 chart-container'>
        <h1 className='display-4 text-center'>Total Donations</h1>
        <Paper>
          <table className='donation-table'>
            <thead>
              <tr>
                {donationTableColumns.map((column) => (
                  <th
                    key={column.label}
                    width={column.width}
                    className='table-header p-1 m-0'
                  >
                    <p className='m-0'>{column.label}</p>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='scrollable'>
              {donations.map((row) => (
                <tr key={row.id}>
                  <td className='p-2'>
                    <div className='donor'>
                      <Avatar src='/'></Avatar>
                      <div>
                        <p className='m-0'>{row.displayName}</p>
                      </div>
                    </div>
                  </td>
                  <td className='p-2' align='center'>
                    {row.donationAmount}
                  </td>
                  <td className='p-2' align='center'>
                    {row.donationCurrency}
                  </td>
                  <td className='p-2' align='center'>
                    {row.donationDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
      </div>
    </section>
  );
}
