import React, { useEffect, useState } from 'react';

/* CSS */
import './DonationTable.scss';

/* Models */
import { acceptedDonationCurrencies } from '../../../../models/currencies';

/* Libraries */
import PaymentIcon from '@material-ui/icons/Payment';
import Avatar from '@material-ui/core/Avatar';
import firebase from '../../../../firebase';
import PropTypes from 'prop-types';

DonationTable.propTypes = {
  openDonationModal: PropTypes.func.isRequired,
};

export default function DonationTable(props) {
  const { openDonationModal } = props;
  const [donations, setDonations] = useState([]);
  const [goalAmount, setGoalAmount] = useState(0);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);

  const fundraisingBenchmarks = [
    {
      weight: '20%',
      label: '20%',
    },
    {
      weight: '20%',
      label: '50%',
    },
    {
      weight: '20%',
      label: '80%',
    },
    {
      weight: '20%',
      label: '100%',
    },
  ];

  useEffect(() => {
    const projectId = 1;

    firebase
      .database()
      .ref(`projects/${projectId}/goalAmount`)
      .on('value', (snapshot) => {
        setGoalAmount(snapshot.val());
      });

    firebase
      .database()
      .ref(`projects/${projectId}/totalDonationAmount`)
      .on('value', (snapshot) => {
        setTotalDonationAmount(snapshot.val());
      });
  }, []);

  /**
   *
   */
  const getProgressBarHeight = () => {
    return Math.ceil(parseFloat((totalDonationAmount / goalAmount) * 100));
  };

  useEffect(() => {
    const projectId = 1;

    firebase
      .database()
      .ref(`projects/${projectId}/donations`)
      .on('value', (snapshot) => {
        let tableEntries = [];
        Object.values(snapshot.val()).forEach((item) => {
          tableEntries.push({
            displayName: item.donorDisplayName,
            donationAmount: sixDecimalPlaces(item.donationAmount),
            donationAmountUSD: '$' + item.donationAmountUSD,
            donationCurrency: item.donationCurrency,
            donationDate: item.donationDate,
            donationTime: item.donationTime,
            tickerSymbol: acceptedDonationCurrencies.find(
              (currency) => currency.network === item.donationCurrency
            ).tickerSymbol,
            transactionId: `${getTransactionID(item.transactionId)}`,
            transactionLink: `${getBlockchainExplorerURL(
              item.donationCurrency
            )}/${item.transactionId}`,
          });
        });

        setDonations(tableEntries.reverse());
      });
  }, []);

  const getBlockchainExplorerURL = (currency) => {
    switch (currency) {
      case 'Bitcoin':
        return 'https://btc.com';
      case 'Ether':
        return 'https://etherscan.io/tx';
      case 'Stellar Lumen':
        return 'https://stellarchain.io/tx'
      default:
        return '/';
    }
  };

  const getTransactionID = (transactionID) => {
    return transactionID
      ? transactionID.substring(0, 13) + '...'
      : 'Pending...';
  };

  const sixDecimalPlaces = (number) => {
    const split = number.toString().split('.');
    const decimalPlaces = split[1];

    if (decimalPlaces && decimalPlaces > 6) {
      split[1] = decimalPlaces.slice(0, 6);
    }

    return split.join('.');
  };

  const hash = (row) => {
    const { transactionId, donationTime, displayName } = row;
    return `${transactionId}-${donationTime}-${displayName}`;
  };

  return (
    <section className='projectPage__section'>
      <div style={{'text-align': '-webkit-center', 'margin-bottom' : 40}}>
        <button
          className='primary-btn donate-button'
          type='button'
          onClick={openDonationModal}
          style={{marginBottom: '5rem'}}
        >
          <span className='button-text'>Donate Digital Currency</span>
        </button>
      </div>
      <div className='donationTable__container'>
        <h2>Project Donations</h2>
        <div className='donation-table'>
          <table>
            <thead>
              <tr>
                <th>Donor</th>
                <th>Amount (USD)</th>
                <th>Amount (Crypto)</th>
                <th>Date / Time</th>
                <th>Transaction ID</th>
              </tr>
            </thead>
            <tbody>
              {donations.map((row) => (
                <tr key={hash(row)}>
                  <td>
                    <div className='donor'>
                      <Avatar />
                      <span>{row.displayName}</span>
                    </div>
                  </td>
                  <td align='center'>{row.donationAmountUSD}</td>
                  <td align='center'>
                    {`${row.donationAmount} `}
                    <strong>{row.tickerSymbol}</strong>
                  </td>
                  <td align='center'>
                    {row.donationDate} <br /> {row.donationTime}
                  </td>
                  <td align='center'>
                    <a
                      className='transaction-id'
                      href={row.transactionLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {row.transactionId}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


{/*        <article className='fundraisingProgress__container'>
        <div className='article-content'>
          <div className='fundraisingProgress__summary'>
            
            <div className='current-total-wrapper'>
              <h3>
                Current Total: $
                {totalDonationAmount.toFixed(2).toLocaleString()}
              </h3>
            </div>
          </div>
          <div className='fundraisingProgress__progress-bar'>
            <div className='progress-meter'>
              <div
                className='amount-filled'
                style={{
                  width: `${getProgressBarHeight()}%`,
                }}
              />
            </div>
            <div className='progress-benchmarks'>
              {fundraisingBenchmarks.map((benchmark) => (
                <div
                  key={benchmark.label}
                  style={{ marginLeft: `${benchmark.weight}` }}
                >
                  <p>{benchmark.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </article>*/}
      </div>
    </section>
  );
}
