import React from 'react';

/* CSS */
import './Inputs.scss';

/* Libraries */
import clsx from 'clsx';
import PropTypes from 'prop-types';

DonationFormInput.propTypes = {
  actionIcon: PropTypes.element,
  icon: PropTypes.element,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export function DonationFormInput(props) {
  const {
    actionIcon,
    icon,
    id,
    label,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    style,
    value,
  } = props;

  const getComponentClasses = () => {
    return clsx(
      'donationFormInput',
      actionIcon && 'actionIconShown',
      icon && 'iconShown',
      value.length > 0 && 'nonEmpty'
    );
  };

  const onClick = () => {
    document.getElementById(id).focus();
  };

  return (
    <div className='donationFormInput__wrapper' style={style}>
      <div className='donationFormInput__container'>
        <div className={getComponentClasses()} onClick={onClick}>
          {!!icon && icon}
          <input
            autoComplete='off'
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            readOnly={readOnly}
            required={required}
            type='text'
            value={value}
          />
          {!!label && <label htmlFor={id}>{label}</label>}
          <span />
          {!!actionIcon && <div className='actionIcon'>{actionIcon}</div>}
        </div>
      </div>
    </div>
  );
}
