import React from 'react';

/* CSS */
import './MilestoneList.scss';

/* Effects */
import { useProjectObjectives } from '../../effects';

export default function MilestoneList() {
  const objectives = useProjectObjectives();

  return (
    <section className='projectPage__section inverted'>
      <div className='projectObjectives__container'>
        <h2>Objectives</h2>
        <div className='projectObjectives__objective-list'>
          {objectives.map((objective) => (
            <div key={objective.id} className='objective-container'>
              <div className="icon">
                {objective.icon}
              </div>
              <div className='objective'>
                <h4>{objective.header}</h4>
                <span>{objective.description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
