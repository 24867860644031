import React from 'react';
import { useSelector } from 'react-redux';

/* Components */
import { PaymentMethodCard, ComingSoonCard } from '../../../components/cards';
import { DonationFormTooltip } from '../../../components/tooltips';

/* Constants */
import { userMessages as messages } from '../../../constants/userMessages';

/* Libraries */
import axios from 'axios';

/* Resources */
import images from '../../../../../assets/images';

export default function OnlineWallet() {
  const {
    donationAmount,
    donationAmountUSD,
    donationCurrency,
    donorDisplayName,
    donorEmailAddress,
    donorFullLegalName,
    isDonorAnonymous,
    willDonorReceiveTaxReceipt,
    willDonorReceiveUpdates,
  } = useSelector((state) => state.donationReducer);

  const getCoinbaseIcon = () => {
    return (
      <img src={images.coinbase} height='100%' width='100%' alt='Coinbase' />
    );
  };

  const getDiemIcon = () => {
    return <img src={images.diem} height='100%' width='100%' alt='Diem' />;
  };

  const getPaypalIcon = () => {
    return <img src={images.paypal} height='100%' width='100%' alt='Paypal' />;
  };

  const getGeminiIcon = () => {
    return <img src={images.gemini} height='100%' width='100%' alt='Gemini' />;
  };

  const getBinanceIcon = () => {
    return (
      <img src={images.binance} height='100%' width='100%' alt='Binance' />
    );
  };

  const getKrakenIcon = () => {
    return <img src={images.kraken} height='100%' width='100%' alt='Kraken' />;
  };

  const getCoinbaseTooltip = () => {
    return {
      position: 'bottom',
      text: messages.COINBASE_TOOLTIP,
    };
  };

  const openCoinbase = async () => {
    const Cryptr = require('cryptr');
    const cryptr = new Cryptr('HeiferLabsIstheBest');
    const redirectString =
      isDonorAnonymous +
      '-' +
      donorDisplayName.replace(' ', '_') +
      '-' +
      donorEmailAddress +
      '-' +
      willDonorReceiveUpdates +
      '-' +
      willDonorReceiveTaxReceipt +
      '-' +
      donationCurrency +
      '-' +
      donationAmount +
      '-' +
      donationAmountUSD +
      '-' +
      donorFullLegalName;

    const encryptedString = cryptr.encrypt(redirectString);
    const redirect =
      'https://donationtracker.heifer.org?data=' + encryptedString;
    axios
      .post(
        'https://api.commerce.coinbase.com/charges',
        {
          name: 'Chocolate 4 All',
          description: 'Fostering sustainable cacao markets',
          local_price: {
            amount: donationAmountUSD,
            currency: 'USD',
          },
          pricing_type: 'fixed_price',
          redirect_url: redirect,
          cancel_url: 'https://donationtracker.heifer.org',
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'X-CC-Api-Key': 'fdeac259-4bbf-4cee-b2b0-fb8c7775ed39',
            'X-CC-Version': '2018-03-22',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        window.location.replace(response.data.data.hosted_url);
      })
      .catch((e) => {
        console.error(e);
      });

    // window.open('https://commerce.coinbase.com/checkout/80427092-d57b-4008-ae22-b3e84cee8b30','_blank')
  };

  return (
    <form>
      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
          <PaymentMethodCard
            body='Connect to Coinbase'
            header='Coinbase'
            icon={getCoinbaseIcon()}
            onClick={openCoinbase}
          />
        </div>
        <div className='col-1 ml-auto flex-column center'>
          <DonationFormTooltip tooltip={getCoinbaseTooltip()} />
        </div>
      </div>
      
      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
          <ComingSoonCard header='Gemini' icon={getGeminiIcon()} />
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col-11 pr-3 col-mb-9 offset-mb-1 pr-mb-0'>
          <ComingSoonCard header='Paypal' icon={getPaypalIcon()} />
        </div>
      </div>
    </form>
  );
}
