import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { PaginationButton } from '../../components/buttons';
import {
  DonationFormContent,
  DonationFormHeader,
  DonationFormSection,
} from '../../components/form-section';
import { PaginationButtonGroup } from '../../components/groups';
import { UserMessage } from '../../components/messages';
import { DonationSteps } from '../../components/stepper';

/* Constants */
import * as section from '../../constants/formSections';
import { userMessages as messages } from '../../constants/userMessages';

/* Dispatch */
import {
  setDonationAmount,
  setDonationCurrency,
  setFormSection,
  setProjectQRCode,
  setProjectWalletAddress,
  toggleIsDonationUnderReview,
  setProjectMemoKey,
  setProjectMemoQRCode,
} from '../../actions/donationActions';

/* Libraries */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

/* Models */
import { acceptedDonationCurrencies } from '../../../../models/currencies';

/* Service */
import { DonationFormContext } from '../../services/DonationFormContext';

export default function SelectCurrency() {
  const {
    donationAmountUSD,
    donationCurrency,
    formSection,
    isDonorAnonymous,
    isDonationUnderReview,
  } = useSelector((state) => state.donationReducer);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const continueToNextSection = () => {
    if (isFormSectionValid()) {
      if (isDonationUnderReview) {
        dispatch(setFormSection(section.REVIEW_DONATION));
        dispatch(toggleIsDonationUnderReview());
      } else {
        dispatch(setFormSection(section.DONATION_AMOUNT));
      }
    }
  };

  const getMenuItems = () => {
    return acceptedDonationCurrencies.map((currency) => {
      const { network, tickerSymbol } = currency;
      return (
        <MenuItem key={network} value={network}>
          {`${network} (${tickerSymbol})`}
        </MenuItem>
      );
    });
  };

  const goBackToPreviousSection = () => {
    if (isDonationUnderReview) {
      if (isFormSectionValid()) {
        dispatch(setFormSection(section.REVIEW_DONATION));
        dispatch(toggleIsDonationUnderReview());
      }
    } else {
      isDonorAnonymous ?
      dispatch(setFormSection(section.DONOR_INFORMATION)) :
      dispatch(setFormSection(section.CUSTOMIZATIONS))
    }
  };

  const isDonationCurrencyEmpty = () => {
    return donationCurrency.length === 0;
  };

  const isFormSectionValid = () => {
    let errorMessage = '';

    if (isDonationCurrencyEmpty()) {
      errorMessage = messages.EMPTY_DONATION_CURRENCY;
    }

    setErrorMessage(errorMessage);
    return errorMessage.length === 0;
  };

  const updateDonationCurrency = (event) => {
    const selectedCurrency = event.target.value.split(' (')[0];
    const {
      conversionRate,
      memoKey,
      memoQrCode,
      network,
      qrCode,
      walletAddress,
    } = acceptedDonationCurrencies.find(
      (currency) => currency.network === selectedCurrency
    );

    dispatch(setDonationAmount(donationAmountUSD * conversionRate));
    dispatch(setDonationCurrency(network));
    dispatch(setProjectQRCode(qrCode));
    dispatch(setProjectWalletAddress(walletAddress));
    dispatch(setProjectMemoKey(memoKey));
    dispatch(setProjectMemoQRCode(memoQrCode));
    setErrorMessage('');
  };

  return (
    <DonationFormContext.Consumer>
      {({ toggleIsFormOpen }) => (
        <DonationFormSection>
          <DonationFormHeader onCloseCallback={toggleIsFormOpen}>
            <h1>Select Currency</h1>
            <DonationSteps activeStep={formSection} />
          </DonationFormHeader>

          <DonationFormContent>
            <form autoComplete='off' noValidate>
              <div className='row no-gutters'>
                <div className='col-10 offset-1'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='donation-currency'>
                      Select Currency
                    </InputLabel>
                    <Select
                      id='donation-currency'
                      onChange={updateDonationCurrency}
                      type='text'
                      value={donationCurrency}>
                      <MenuItem disabled>Choose One</MenuItem>
                      {getMenuItems()}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {!!errorMessage && (
                <div className='row no-gutters'>
                  <div className='col-12 col-mb-10 offset-mb-1'>
                    <UserMessage message={errorMessage} type='error' />
                  </div>
                </div>
              )}
            </form>
          </DonationFormContent>

          <footer>
            <PaginationButtonGroup>
              <PaginationButton
                onClickHandler={continueToNextSection}
                type='primary'>
                Continue
              </PaginationButton>
              <PaginationButton
                onClickHandler={goBackToPreviousSection}
                type='secondary'>
                Previous
              </PaginationButton>
            </PaginationButtonGroup>
          </footer>
        </DonationFormSection>
      )}
    </DonationFormContext.Consumer>
  );
}
