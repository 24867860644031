import React from 'react';

/* CSS */
import './Buttons.scss';

/* Libraries */
import clsx from 'clsx';
import PropTypes from 'prop-types';

PaginationButton.propTypes = {
  children: PropTypes.node,
  onClickHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export function PaginationButton(props) {
  const { onClickHandler, type } = props;

  const dictionary = {
    primary: 'primary-btn',
    secondary: 'secondary-btn',
    success: 'success-btn',
    danger: 'danger-btn',
  };

  return (
    <div className='paginationButton__wrapper'>
      <button
        className={dictionary[type]}
        type='button'
        onClick={onClickHandler}>
        {props.children}
      </button>
    </div>
  );
}

ActionButton.propTypes = {
  children: PropTypes.node,
  onClickHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export function ActionButton(props) {
  const { onClickHandler, type } = props;

  const dictionary = {
    action: 'action',
    success: 'success',
  };

  const getButtonClasses = () => {
    return clsx('action-btn', dictionary[type]);
  };

  return (
    <button
      className={getButtonClasses()}
      disabled={type === 'success'}
      type='button'
      onClick={onClickHandler}>
      {props.children}
    </button>
  );
}
