import { combineReducers } from 'redux';
import donationReducer from '../../pages/donation-modal/reducer/donationReducer';
import projectReducer from '../../pages/project-page/reducer/projectReducer';

const rootReducer = combineReducers({
  donationReducer,
  projectReducer,
});

export default rootReducer;
