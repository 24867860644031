import React, { useState } from 'react';

/* CSS */
import './CreateAccount.css';

export default function CreateAccount() {
  const [form, setForm] = useState({
    displayName: '',
    email: '',
    password: '',
  });

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <section className='create-account-page'>
      <div className='account-panel'>
        <h1>
          <a href='/'>HEIFER</a> | Create Account
        </h1>
        <div className='signup-options'>
          <button className='col-8 offset-2 account-link google'>
            Sign up with Google
          </button>
          <button className='col-8 offset-2 account-link facebook'>
            Sign up with Facebook
          </button>

          <hr />

          <form autoComplete='off' onSubmit={(event) => event.preventDefault()}>
            <div className='col-8 offset-2 form-field'>
              <div className='input-container'>
                <input
                  id='display-name'
                  type='text'
                  name='displayName'
                  value={form.displayName}
                  onChange={onInputChange}
                  required
                />
                <label htmlFor='display-name'>Display Name</label>
                <span></span>
              </div>
            </div>

            <div className='col-8 offset-2 form-field'>
              <div className='input-container'>
                <input
                  id='email'
                  type='text'
                  name='email'
                  value={form.email}
                  onChange={onInputChange}
                  required
                />
                <label htmlFor='email'>Email</label>
                <span></span>
              </div>
            </div>

            <div className='col-8 offset-2 form-field'>
              <div className='input-container'>
                <input
                  id='password'
                  type='password'
                  name='password'
                  value={form.password}
                  onChange={onInputChange}
                  required
                />
                <label htmlFor='password'>Password</label>
                <span></span>
              </div>
            </div>

            <button className='col-6 offset-3 submit-button'>Register</button>
          </form>
        </div>
      </div>
    </section>
  );
}
