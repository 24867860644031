// import * as types from '../actions/actionTypes';

const initialState = {};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
