import React from 'react';

/* Components */
import Rating from '@material-ui/lab/Rating';

/* Styles */
import './index.scss';

export function StarRating(props) {
  const { onStarSelect } = props;

  const handleChange = (value) => {
    !!value ? onStarSelect(value) : onStarSelect(0);
  };

  return (
    <Rating
      classes={{ label: 'star' }}
      name='rating'
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      size='large'
    />
  );
}
