import React from 'react';

/* CSS */
import './Footer.scss';

export default function Footer() {
  return (
    <footer className='simpleFooter'>
      <nav>
        <div className='simpleFooter__wrapper'>
          <div className='simpleFooter__content'>
            <div className='simpleFooter__header-container'>
              <a
                className='simpleFooter__header'
                href='https://www.heifer.org/about-us/contact.html'
              >
                <h5>Contact Us</h5>
                <h5>Live Chat</h5>
              </a>
            </div>
            <div className='simpleFooter__details'>
              <span>© 2019 Heifer International. All Rights Reserved.</span>
              <a
                className='simpleFooter__phone'
                href='https://www.heifer.org/campaign/tel:855-948-6437'
              >
                855.9HUNGER (855.948.6437)
              </a>
              <div className='simpleFooter__links'>
                <a href='https://www.heifer.org/support/privacy-policy.html'>
                  Privacy Policy
                </a>
                <span>|</span>
                <a href='https://www.heifer.org/support/charitable-solicitation-disclosure-statements.html'>
                  Charitable Solicitation Disclosure Statements
                </a>
              </div>
              <span className='simpleFooter__disclaimer'>
                Heifer International is a 501(C)(3) nonprofit charity registered
                in the US under EIN: 35-1019477.
              </span>
            </div>
          </div>
          <div className='simpleFooter__icons-container'>
            <div className='simpleFooter__icons'>
              <img
                src='https://media.heifer.org/Resource/footer-sealoftransparency.png'
                alt='Seal of Transparency, 2019 Platinum'
              />
              <img
                src='https://media.heifer.org/Resource/footer-bbb.svg'
                alt='Better Business Bureau'
              />
              <img
                src='https://media.heifer.org/Resource/footer-compass.svg'
                alt='Charity Navigator'
              />
              <img
                src='https://media.heifer.org/Resource/footer-hilton.svg'
                alt='Conrad N. Hilton Humanitarian Prize'
              />
              <img
                src='https://media.heifer.org/Resource/footer-interaction.svg'
                alt='InterAction Member Standards'
              />
            </div>
          </div>
        </div>
      </nav>
    </footer>
  );
}
